import axios from 'axios';

const defaultState = {
  gameInfo: null,
  question: null,
  currentStage: 1,
  isCounterActive: false,
  showFinal: false,
};

const mutations = {
  SET_IS_COUNTER_ACTIVE: (state, payload) => {
    state.isCounterActive = payload;
  },
  SET_CURRENT_STAGE: (state, payload) => {
    state.currentStage = payload;
  },
  SET_GAME_INFO: (state, payload) => {
    state.gameInfo = payload;
  },
  SET_SHOW_FINAL: (state, payload) => {
    state.showFinal = payload;
  },
  SET_QUESTION: (state, payload) => {
    state.question = payload;
  },
  GAME_INFO_RESET: (state) => {
    state.gameInfo = null;
  },
};

const actions = {
  activateCounter({ commit }) {
    commit('SET_IS_COUNTER_ACTIVE', true);
  },
  pauseCounter({ commit }) {
    commit('SET_IS_COUNTER_ACTIVE', false);
  },
  setCurrentStage({ commit }, data) {
    commit('SET_CURRENT_STAGE', data);
  },
  upStage({ commit, state }) {
    commit('SET_CURRENT_STAGE', state.currentStage + 1);
  },
  clearQuestion({ commit }) {
    commit('SET_QUESTION', {});
  },
  async fetchQuestion({ commit, state /* , dispatch */ }, stage = null) {
    try {
      const currentStageId = state
        .gameInfo?.stages.find((item) => item.stage === (stage || state.currentStage)).id;
      if (currentStageId) {
        const { data } = await axios.get(`/game/get-text-question/${currentStageId}`);
        commit('SET_QUESTION', data);
        console.log('[vuex-game] fetchQuestion done', data);
      }
    } catch (e) {
      console.log('[vuex-user] fetchQuestion error', e);
    }
  },
  async fetchGame({ commit/* , dispatch */ }, setStage = false) {
    console.log('[vuex-game] fetchGame start');
    try {
      const { data } = await axios.get('/game');
      console.log('[vuex-user] fetchGame data', data);
      if (data && data.id) {
        const gameInfo = { ...data, stages: data.stages.sort((a, b) => a.stage - b.stage) };
        commit('SET_GAME_INFO', gameInfo);
        console.log('currentStage');
        if (setStage) {
          const currentStage = gameInfo.stages.find(
            (item) => item.answered_questions < 5,
          )?.stage;
          commit('SET_CURRENT_STAGE', currentStage);
        }
      } /* else {
        dispatch('startGame');
        const { data: gameNew } = axios.get('/game');
        console.log('[vuex-game] fetchGame gameNew', gameNew);
        commit('SET_GAME_INFO', gameNew);
      } */
      console.log('[vuex-game] fetchGame done', data);
    } catch (e) {
      console.log('[vuex-user] fetchGame error', e);
      // await dispatch('logout');
    }
  },
  async startGame({ commit/* , dispatch */ }, setStage = false) {
    console.log('[vuex-game] startGame start');
    try {
      const { data } = await axios.post('/game/new-game');
      console.log('[vuex-user] fetchGame data', data);
      if (data && data.id) {
        const gameInfo = { ...data, stages: data.stages.sort((a, b) => a.stage - b.stage) };
        commit('SET_GAME_INFO', gameInfo);
        console.log('currentStage');
        if (setStage) {
          const currentStage = gameInfo.stages.find(
            (item) => item.answered_questions < 5,
          )?.stage;
          commit('SET_CURRENT_STAGE', currentStage);
        }
      }
      console.log('[vuex-game] startGame done');
    } catch (e) {
      console.log('[vuex-user] startGame error', e);
      // await dispatch('logout');
    }
  },
  async restartGame({ commit, dispatch }) {
    console.log('[vuex-game] restartGame start');
    commit('SET_CURRENT_STAGE', 1);
    commit('SET_IS_COUNTER_ACTIVE', false);
    commit('user/SET_INTRO_SHOWN', false, { root: true });
    commit('SET_SHOW_FINAL');
    localStorage.removeItem('counter');
    await dispatch('finishGame');
    commit('GAME_INFO_RESET');
    console.log('[vuex-game] restartGame done');
  },
  async finishGame() {
    console.log('[vuex-game] finishGame done');
    // await axios.post('/game/finish')
    await axios.post('/game/new-game');
    console.log('[vuex-game] finishGame done');
  },
};

const getters = {
  code: (state) => (state.gameInfo.stages ? state.gameInfo.stages.map((s) => s.code_part) : []),
  codeLetter: (state) => {
    const currentStage = state.gameInfo.stages.find((s) => s.stage === state.currentStage);
    return currentStage.code_part || false;
  },
  timeBeforeGame: () => {
    const gameStartAt = new Date('2020-04-13T10:35');
    const now = new Date();
    const timeDiffSeconds = (gameStartAt - now) / 1000;
    return timeDiffSeconds;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters,
};
