<template>
  <table class="top-table">
    <thead>
      <tr>
        <td>№</td>
        <td class="text-left">Игрок</td>
        <td class="text-left">Очки</td>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(player, idx) in players"
        :class="{ current: user.id === player.id }"
        :key="idx"
      >
        <td>{{ player.rank }}</td>
        <td class="pr-16 text-left">{{ player.name || 'sanasol' }}</td>
        <td>{{ player.score }}</td>
      </tr>
      <tr
        class="current"
        v-if="
          user.id &&
          gameInfo &&
          gameInfo.rank &&
          !players.find((player) => player.id === user.id)
        "
      >
        <td>{{ gameInfo.rank }}</td>
        <td class="pr-16 text-left">{{ user.username }}</td>
        <td>{{ gameInfo.score }}</td>
      </tr>
    </tbody>
    <!-- <tbody v-else>
      <tr
        v-for="(player, idx) in 6"
        :key="idx"
      >
        <td><div style="width: 40px; height: 40px;
        background: #2E3035; border-radius: 50%" /></td>
        <td>
          <div style=" width: 190px; height: 10px;  border-radius: 5px;
            background: #2E3035;" />
          <div style=" width: 60px; height: 10px;  border-radius: 5px;
            margin-top: 10px; background: #2E3035;" />
        </td>
        <td></td>
      </tr>
      <tr class="current">
        <td>{{ 1 }}</td>
        <td class="pr-16 text-left">{{ 1 || 'sanasol' }}</td>
        <td>{{ 1 }}</td>
      </tr>
    </tbody> -->
  </table>
</template>

<script>
export default {
  name: 'TopTable',
  data() {
    return {
      players: [],
    };
  },
  async created() {
    try {
      const response = await this.$http.get('/scoreboard?limit=5');
      this.players = response.data.collection;
    } catch (e) {
      console.log('[fetch-scoreboard] error', e);
    }
    console.log(this.players);
  },
  computed: {
    user() {
      return this.$store.getters['user/user'];
    },
    gameInfo() {
      return this.$store.state.game.gameInfo;
    },
  },
};
</script>

<style lang="scss" scoped>
.top-table {
  @apply font-croc-bold w-full;
  border-spacing: 0 4px;
  border-collapse: separate;
  // border-radius: 10px;
  & thead {
    @apply text-12 leading-18 text-green;
    & tr {
      background: transparent;
    }
    & td {
      @apply py-4;

      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
  }
  & tbody {
    @apply text-16 leading-16;
    color: rgba(255, 255, 255, 0.8);
    tr:last-child td:first-child { border-bottom-left-radius: 10px; }
    tr:last-child td:first-child { border-top-left-radius: 10px; }
    tr:last-child td:last-child { border-bottom-right-radius: 10px; }
    tr:last-child td:last-child { border-top-right-radius: 10px; }
    & tr {
      // background: linear-gradient(
      //     0deg,
      //     rgba(0, 164, 96, 0.05),
      //     rgba(0, 164, 96, 0.05)
      //   ),
      //   #101010;
      // border: 4px solid red;
      &.current {
        background: #15A86A;
        border-radius: 10px;
        overflow: hidden;
        // outline: 2px solid red;
        // border: 1px solid red;
        // border-collapse: inherit;
        :first-child {
          color: rgba(255, 255, 255, 0.4);
        }
      }
      :first-child {
        @apply text-center;
        width: 49px;
        color: rgba(255, 255, 255, 0.8);
        // border-left: 1px solid rgba(0, 164, 96, 0.5);
      }
      :last-child {
        // border-right: 1px solid rgba(0, 164, 96, 0.5);
        @apply pr-12;
      }
    }
    & td {
      @apply py-16;
      // border-top: 1px solid rgba(0, 164, 96, 0.5);
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
  }
}

@screen sm {
  .top-table {
    width: 404px;
  }
}

@screen xl {
  .top-table {
    width: 474px;
    & thead {
      & tr {
      }
      & td {
      }
    }
    & tbody {
      @apply text-22 leading-22;
      & tr {
        &.current {
        }
        :first-child {
          width: 87px;
        }
        :last-child {
          @apply pr-24;
        }
      }
      & td {
        @apply py-24;
      }
    }
  }
}
</style>
