<!-- eslint-disable max-len -->
<template>
  <svg width="54" height="48" viewBox="0 0 54 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g opacity="0.8">
  <rect x="0.5" y="0.5" width="53" height="47" rx="3.5" fill="#00A460" fill-opacity="0.05"/>
  <path d="M27 12C20.373 12 15 17.373 15 24C15 29.623 18.872 34.328 24.092 35.63C24.036 35.468 24 35.28 24 35.047V32.996C23.513 32.996 22.697 32.996 22.492 32.996C21.671 32.996 20.941 32.643 20.587 31.987C20.194 31.258 20.126 30.143 19.152 29.461C18.863 29.234 19.083 28.975 19.416 29.01C20.031 29.184 20.541 29.606 21.021 30.232C21.499 30.859 21.724 31.001 22.617 31.001C23.05 31.001 23.698 30.976 24.308 30.88C24.636 30.047 25.203 29.28 25.896 28.918C21.9 28.507 19.993 26.519 19.993 23.82C19.993 22.658 20.488 21.534 21.329 20.587C21.053 19.647 20.706 17.73 21.435 17C23.233 17 24.32 18.166 24.581 18.481C25.477 18.174 26.461 18 27.495 18C28.531 18 29.519 18.174 30.417 18.483C30.675 18.17 31.763 17 33.565 17C34.297 17.731 33.946 19.656 33.667 20.594C34.503 21.539 34.995 22.66 34.995 23.82C34.995 26.517 33.091 28.504 29.101 28.917C30.199 29.49 31 31.1 31 32.313V35.047C31 35.151 30.977 35.226 30.965 35.315C35.641 33.676 39 29.236 39 24C39 17.373 33.627 12 27 12Z" fill="#23E191"/>
  <rect x="0.5" y="0.5" width="53" height="47" rx="3.5" stroke="url(#paint0_linear_459:1675)"/>
  </g>
  <defs>
  <linearGradient id="paint0_linear_459:1675" x1="-6.16304" y1="29" x2="61.0435" y2="29" gradientUnits="userSpaceOnUse">
  <stop stop-color="#23E191" stop-opacity="0.9"/>
  <stop offset="0.520349" stop-color="#181818"/>
  <stop offset="1" stop-color="#21EB96" stop-opacity="0.9"/>
  <stop offset="1" stop-color="#23E191" stop-opacity="0.9"/>
  </linearGradient>
  </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconGit',
  components: {},
  data() {
    return {};
  },
};
</script>
