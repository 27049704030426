<template>
  <div>{{ stringTime }}</div>
</template>

<script>
export default {
  name: 'Timer',
  props: {
    interval: {
      type: [String, Number],
      required: true,
    },
    handleEndTime: {
      type: Function,
      default: (ctx) => {
        ctx.stopTimer();
      },
    },
    withHours: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentTime: Math.round(this.interval),
      timer: null,
    };
  },
  computed: {
    stringTime() {
      const numdays = Math.floor((this.currentTime % 31536000) / 86400) * 24;
      const numhours = (
        numdays + Math.floor(((this.currentTime % 31536000) % 86400) / 3600)
      )
        .toString()
        .padStart(2, '0');
      const numminutes = Math.floor((((this.currentTime % 31536000) % 86400) % 3600) / 60)
        .toString()
        .padStart(2, '0');
      const numseconds = ((((this.currentTime % 31536000) % 86400) % 3600) % 60)
        .toString()
        .padStart(2, '0');
      if (this.withHours) {
        return `${numhours}:${numminutes}:${numseconds}`;
      }

      return `${numminutes}:${numseconds}`;
    },
  },
  watch: {
    interval(val) {
      this.currentTime = val;
    },
  },
  mounted() {
    this.startTimer();
  },
  destroyed() {
    this.stopTimer();
  },
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        this.currentTime -= 1;

        if (this.currentTime <= 0) {
          this.handleEndTime(this);
          this.stopTimer();
        }
      }, 1000);
    },
    stopTimer() {
      clearTimeout(this.timer);
    },
  },
};
</script>

<style lang="scss">
</style>
