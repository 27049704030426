<template>
  <modal
    name="settings-modal"
    class="settings-modal"
    :scrollable="true"
    :clickToClose="false"
    :adaptive="true"
    width="100%"
    height="100%"
  >
    <button class="close" @click="closeModal">
      <IconCloseSquare />
    </button>
    <Settings :user="user" :logout="logout" :docs="docs" />
  </modal>
</template>

<script>
import Settings from '@/components/Settings.vue';
import IconCloseSquare from '@/components/Icons/IconCloseSquare.vue';
import { SETTINGS_DOCS } from '@/consts';

export default {
  name: 'ModalSettings',
  components: {
    Settings,
    IconCloseSquare,
  },
  data() {
    return {
      docs: SETTINGS_DOCS,
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
  },
  methods: {
    closeModal() {
      this.$modal.hide('settings-modal');
    },
    logout() {
      this.$store.dispatch('user/logout').then(() => {
        this.closeModal();
      });
    },
  },
  created() {
    console.log(this.docs);
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        this.closeModal();
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.settings-modal {
  @apply absolute;
}

.close {
  @apply absolute;
  right: 5px;
  top: 5px;
}
</style>
