<template>
  <div class="about">
    <div class="flex flex-col lg:flex-row sm:space-x-20 space-x-0">
      <div class="flex flex-col w-full">
        <h2 class="landing-h2"
        style="margin-bottom: 0px !important;">
        О компании croc<span style="color: #b7d700">_</span>code</h2>
        <div class="flex flex-row">
          <!-- eslint-disable -->
          <svg style="margin-top: 40px; margin-right: 40px;" width="100" height="100" viewBox="0 0 65 74" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 13.2236V12.1919L19.9817 0V2.67285L3.47301 12.7077L19.9817 22.8364V25.5093L0 13.2236Z" fill="white"/>
            <path d="M39.5288 9.4362L41.4794 10.1396L25.5416 52.4362L23.5435 51.6859L39.5288 9.4362Z" fill="#B7D700"/>
            <path d="M45.0659 73.9455V71.2726L61.5746 61.1439L45.0659 51.109V48.4362L65 60.6281V61.6598L45.0659 73.9455Z" fill="white"/>
          </svg>
          <div class="flex flex-col pr-100">
            <p class="about-text" style="margin-bottom: 0px !important; margin-top: 50px;">
              Croc_Code занимается полным циклом работ по заказной разработке и развивает собственную линейку продуктов и сервисов с применением современного стека технологий, включая: Java, .Net, Python, Swift, Kotlin, Low-Code & No-Code платформы, микросервисы, DevOps, облачные вычисления.
            </p>
            <p class="about-text" style="margin-top: 20px !important" >
              Мы прислушиваемся к идеям коллег и вместе работаем над улучшением инженерной культуры и процессов в командах. Стек технологий зависит от проекта и задачи, которую мы помогаем решить заказчику. 
            </p>
          </div>
        </div>
      </div>
      <div class="about__stat-container">
        <h3 class="font-croc-light" style="font-size: 32px; line-height: 100%;
        font-weight: normal; margin-bottom: 50px;">О нас в цифрах</h3>
        <div class="about__stat-item" v-for="stat in stats" :key="stat.name">
          <div class="about__stat-item-value">
            {{ stat.value }}
          </div>
          <div class="about__stat-item-name">
            {{ stat.name }}
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="about__descr-container">
      <div
        class="about__descr-item"
        v-for="descr in descrItems"
        :key="descr.title"
      >
        <div class="about__descr-item-icon">
          <component :is="descr.icon"></component>
        </div>
        <div class="about__descr-item-title">{{ descr.title }}</div>
        <div
          class="about__descr-item-line"
          v-for="item in descr.items"
          :key="item"
        >
          {{ item }}
        </div>
      </div>
    </div> -->
    <landing-vacancies />
    <!-- <h2 class="landing-h2 mt-32 lg:mt-60 mb-32">Наш офис</h2> -->
    <!-- <swiper class="swiper" :options="swiperOption">
      <swiper-slide
        :class="`slide-${i}`"
        v-for="i in 6"
        :key="`slide-${i}`"
      ></swiper-slide>
    </swiper> -->
    <div class="gallery-pagination" />
    <p class="uppercase text-12 leading-24 mt-40 mb-16">мы в соцсетях:</p>
    <croc-social-network-links />
  </div>
</template>

<script>
// import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
// import IconChart from '../Icons/IconChart.vue';
// import IconFriends from '../Icons/IconFriends.vue';
// import IconCare from '../Icons/IconCare.vue';
import IconHandshake from '../Icons/IconHandshake.vue';
import '../../../node_modules/swiper/swiper.scss';
import CrocSocialNetworkLinks from '../CrocSocialNetworkLinks.vue';
import LandingVacancies from './LandingVacancies.vue';

export default {
  components: {
    // IconChart,
    // IconFriends,
    // IconCare,
    // IconHandshake,
    // Swiper,
    // SwiperSlide,
    CrocSocialNetworkLinks,
    LandingVacancies,
  },
  name: 'LandingVacancied',
  // directives: {
  //   swiper: directive,
  // },
  data() {
    return {
      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 26,
        pagination: {
          el: '.gallery-pagination',
          clickable: true,
          renderBullet(index, className) {
            return `<button
          class="${className} gallery-pagination__item"
        ></button>`;
          },
        },
        mousewheel: true,
        speed: 2000,
      },
      stats: [
        { value: '750 сотрудников', name: 'Разработчики (front-end, back-end, mobile), архитекторы, системные и бизнес-аналитики, эксперты в UI/UX, тестировщики, консультанты по внедрению, project-менеджеры и DevOps-специалисты' },
        {
          value: '7 центров разработки',
          name: 'Москва, Санкт-Петербург, Нижний Новгород, Воронеж, Краснодар, Челябинск, Иркутск',
        },
        { value: '25 регионов', name: 'России и мира охватывает география проектных команд. Работаем, где удобно – в одном из центров разработки или удаленно' },
        { value: '13 практик', name: 'Развиваем центры компетенций в 13 направлениях – от государственных информационных систем и решений для промышленности, но мобильной разработки и аппаратных решений' },
        { value: 'Каждый сотрудник в среднем проходит 7 обучений в году', name: 'Посещает внешние конференции и курсы, программы корпоративного университета и курсы английского языка. Мы полностью оплачиваем обучение и сертификацию, а также помогаем построить личный трек развития' },
      ],
      descrItems: [
        {
          title: 'Гибкость',
          items: [
            'Можно перемещаться между стеками и проектами',
            'Находишь возможности внутри, а не выходишь за ними на рынок',
            'Проекты для частных компаний, межународных, госов',
          ],
          icon: 'icon-chart',
        },
        {
          title: 'Команда',
          items: [
            'Нетоксичный коллектив, свои ребята',
            'Поддержка, все очень дружелюбные',
            'Много крутых экспертов',
          ],
          icon: 'icon-friends',
        },
        {
          title: 'Проекты',
          items: [
            'Твоя работа влияет на жизнь людей, делаем то, чем пользуются миллионы',
          ],
          icon: 'icon-handshake',
        },
      ],
    };
  },
};
</script>

<style lang="scss">

.about {
  &-text {
    @apply font-croc-light sm:text-32 sm:leading-48 mt-32 xl:mt-76 mb-36
    sm:mb-60 lg:mb-84;
    font-size: 20px;
    line-height: 140%;
    max-width: 839px;
    color: #B9B9B9;
  }
  &__descr {
    &-container {
      @apply sm:flex justify-between;
    }
    &-item {
      @apply relative mb-40;
      &-icon {
        @apply absolute top-0 left-0;
      }
      &-title {
        @apply text-18 sm:text-24 leading-36 pl-52 mb-24;
      }
      &-line {
        @apply pl-16 text-16 leading-24 mb-4 relative font-croc-light;
        &:after {
          @apply absolute left-0;
          content: '';
          top: calc(50% - 3px);
          --size: 6px;
          width: var(--size);
          height: var(--size);
          background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='3' cy='3' r='3' fill='url(%23paint0_linear)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='-4.24683e-08' y1='6' x2='6' y2='0.3' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2300A460'/%3E%3Cstop offset='1' stop-color='%2373E553'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
        }
      }
    }
  }
  &__stat {
    &-container {
      @apply flex flex-col sm:w-2/3 w-full ;
      margin-top: 16px !important;
      margin-left: 10px !important;
    }
    &-item {
      @apply inline-block mb-16 mt-16 align-top cursor-pointer;
      max-width: 600px;
      &-value {
        @apply font-croc-bold text-20 leading-20 pb-4;
        color: #fff;
      }
      &-name {
        @apply text-16 leading-24 pb-8 font-croc-light;
        color: #B9B9B9;
        font-weight: 200;
        // padding-bottom: 10px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
      }
    }
  }
}
.swiper {
  .swiper-slide {
    @apply rounded-4;
    width: 296px;
    height: 187px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    &.slide-1 {
      background-image: url('/images/office-1.jpg');
    }
    &.slide-2 {
      background-image: url('/images/office-2.jpg');
    }
    &.slide-3 {
      background-image: url('/images/office-3.jpg');
    }
    &.slide-4 {
      background-image: url('/images/office-4.jpg');
    }
    &.slide-5 {
      background-image: url('/images/office-5.jpg');
    }
    &.slide-6 {
      background-image: url('/images/office-6.jpg');
    }
  }
}
@screen sm {
  .about {
    &__stat-item {
      width: 100%;
    }
    &__descr {
      &-container {
        @apply -mx-24;
      }
      &-item {
        @apply mx-24 mb-96;
        // width: 40%;
      }
    }
  }
  .swiper {
    .swiper-slide {
      width: 506px;
      height: 337px;
    }
  }
}
@screen lg {
  .swiper {
    .swiper-slide {
      width: 570px;
      height: 379px;
    }
  }
}

.gallery-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
  height: 5px;
  margin: 40px -6px 0 -6px;
  & > button.gallery-pagination__item.swiper-pagination-bullet {
    @apply rounded-16 bg-gray-300 inline-block align-top;
    width: calc(100% / 6 - 12px);
    height: 5px;
    margin: 0 6px;
    opacity: 0.7;
    &.swiper-pagination-bullet-active {
      background: #00a460;
      opacity: 1;
    }
  }
}
@screen sm {
  .gallery-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
    height: 5px;
    margin: 40px -6px 0 -6px;
    & > button.gallery-pagination__item.swiper-pagination-bullet {
      @apply rounded-16 bg-gray-300 inline-block align-top;
      width: 75px;
      height: 5px;
      margin: 0 6px;
      opacity: 0.7;
      &.swiper-pagination-bullet-active {
        background: #00a460;
        opacity: 1;
      }
    }
  }
}
</style>
