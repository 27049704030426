<!-- eslint-disable max-len -->
<template>
  <svg width="54" height="48" viewBox="0 0 54 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g opacity="0.8">
  <rect x="0.5" y="0.5" width="53" height="47" rx="3.5" fill="#00A460" fill-opacity="0.05"/>
  <path d="M30 29.3477C30.0005 29.1165 29.9552 28.8875 29.8667 28.6741C29.7783 28.4606 29.6485 28.267 29.485 28.1045L29.4241 28.0527C29.3644 27.9944 29.3002 27.9408 29.2321 27.8927C29.1884 27.8648 29.1431 27.8396 29.0963 27.8173C29.0464 27.7863 28.9948 27.758 28.9418 27.7325C28.8809 27.7064 28.8184 27.6844 28.7546 27.6667L28.6188 27.6242C28.547 27.6196 28.4751 27.6196 28.4034 27.6242H28.2863H26.5727H17.2553C17.0231 27.6242 16.8004 27.5315 16.6362 27.3663C16.472 27.2012 16.3797 26.9772 16.3797 26.7437V17.2885C16.3797 17.055 16.472 16.831 16.6362 16.6659C16.8004 16.5007 17.0231 16.408 17.2553 16.408H29.9906V14.0536V13.7664C29.9906 13.298 29.8056 12.8489 29.4764 12.5177C29.1471 12.1866 28.7005 12.0006 28.2348 12.0006H16.8479C15.6692 11.9825 14.5248 12.3996 13.6313 13.173C13.1131 13.614 12.6982 14.1648 12.4165 14.7859C12.1348 15.4071 11.9933 16.0832 12.0019 16.7658V26.9321C11.9824 27.6495 12.1113 28.3632 12.3805 29.0279C12.6497 29.6926 13.0533 30.2937 13.5658 30.7932C14.428 31.5946 15.5665 32.0269 16.7402 31.9987H30V29.3477Z" fill="#23E191"/>
  <path d="M32 32V36H42V32H32Z" fill="#23E191"/>
  <rect x="0.5" y="0.5" width="53" height="47" rx="3.5" stroke="url(#paint0_linear_459:1110)"/>
  </g>
  <defs>
  <linearGradient id="paint0_linear_459:1110" x1="-6.16304" y1="29" x2="61.0435" y2="29" gradientUnits="userSpaceOnUse">
  <stop stop-color="#23E191" stop-opacity="0.9"/>
  <stop offset="0.520349" stop-color="#181818"/>
  <stop offset="1" stop-color="#21EB96" stop-opacity="0.9"/>
  <stop offset="1" stop-color="#23E191" stop-opacity="0.9"/>
  </linearGradient>
  </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconSite',
  components: {},
  data() {
    return {};
  },
};
</script>
