<template>
  <div class="text-center sm:text-left">
    <p class="text-28 leading-24 mb-28">
      Призы за пробуждение
    </p>
    <div class="prize__container">
      <div class="prize">
        <div class="prize__img prize__img-first"></div>
        <div class="prize__quantity">Пяти лучшим аналитикам подарим</div>
        <div class="prize__name">
          Руководство<br />к своду знаний<br />по бизнес-анализу
        </div>
      </div>
      <!-- <div class="prize">
        <div class="prize__img prize__img-second"></div>
        <div class="prize__quantity">2 место</div>
        <div class="prize__name">Наушники HyperX Cloud Alpha S Black</div>
      </div>
      <div class="prize">
        <div class="prize__img prize__img-third"></div>
        <div class="prize__quantity">3 место</div>
        <div class="prize__name">Беспроводная акустика JBL Flip 5 Teal</div>
      </div>
      <div class="prize">
        <div class="prize__img prize__img-four"></div>
        <div class="prize__quantity">4 место</div>
        <div class="prize__name">Рюкзак Navigator для ноутбука 15,6"</div>
      </div>
      <div class="prize">
        <div class="prize__img prize__img-five"></div>
        <div class="prize__quantity">5 место</div>
        <div class="prize__name">Рюкзак Navigator для ноутбука 15,6"</div>
      </div> -->
      <p class="prize__rules">Призы разыгрываются только в дни
        конференции среди всех игроков.
        <br />Попаcть в список победителей можно
        только один раз за конференцию.
        <br />Читайте <a :href="rulesLink" target="_blank">правила полностью</a>
      </p>
    </div>
  </div>
</template>

<script>
import { DOCS } from '../../consts';

export default {
  data() {
    return {
      rulesLink: DOCS.RULES,
    };
  },
};
</script>

<style lang="scss" scoped>
.prize {
  @apply inline-block rounded-4 relative text-left;
  margin: 14px;
  padding: 24px 0px 48px 24px;
  background: linear-gradient(
      270deg,
      rgba(35, 225, 145, 0.5) -80.06%,
      #1B1B1B
    ),
    #101010;
  border-color: rgba(0, 164, 96, 0.5);
  max-width: calc(100% - 34px);
  width: 100%;
  // height: 100px;
  &__container {
    @apply -m-12 text-center flex-col sm:text-left;
    display: flex;
    justify-content: flex-start;
    max-width: 600px;
    // border: 1px solid red;
  }
  &__quantity {
    font-size: 13px;
    line-height: 100%;
    color: #8e8e8e;
    margin-bottom: 9px;
  }
  &__name {
    @apply font-croc-bold;
    font-weight: bold;
    font-size: 25px;
    line-height: 120%;
    max-width: 50%;
    // line-height: 130%;
    color: #ffffff;
  }
  &__img {
    @apply absolute right-0 bg-contain bg-center bg-no-repeat;
    bottom: 0px;
    width: 128px;
    // border: 1px solid red;
    &-first {
      width: 90%;
      margin-right: -20%;
      // border: 1px solid red;
      height: 200%;
      top: -60%;
      background-image: url('/images/babok.png');
    }
    &-second {
      width: 155px;
      height: 128px;
      background-image: url('/images/playstation.png');
    }
    &-third {
      width: 155px;
      height: 128px;
      background-image: url('/images/raptor.png');
    }
    &-four {
      width: 145px;
      height: 118px;
      background-image: url('/images/speakers.png');
    }
    &-five {
      width: 145px;
      height: 118px;
      background-image: url('/images/nokia.png');
    }
  }
  &__rules {
    @apply w-auto ;
    font-weight: normal;
    text-align: left !important;
    font-size: 14px;
    line-height: 120%;
    color: #AEADB1;
    margin-top: 20px;
    margin-left: 17px;
    & a {
      color: #fff;
      text-decoration: underline;
    }
  }
}
</style>
