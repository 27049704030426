<template>
  <Button
    is-next
    is-text-colored
    is-full-width
    is-uppercase
    href="https://t.me/CrocRandomCoffeeBot"
    tag="a"
    target="_blank"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <template #icon>
      <img class="mr-8" :src="isHovered ? $options.tgHover : $options.tg" />
    </template>
    <slot v-if="!!$slots.default" />
    <template v-else>
      Найти собеседника
    </template>
  </Button>
</template>

<script>
import tg from '@/assets/images/tg.svg';
import tgHover from '@/assets/images/tgHover.svg';

import Button from '@/components/UI/Button.vue';

export default {
  tg,
  tgHover,
  data() {
    return {
      isHovered: false,
    };
  },
  components: {
    Button,
  },
};
</script>
