<!-- eslint-disable max-len -->
<template>
  <svg
    width="86"
    height="48"
    viewBox="0 0 86 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.8">
      <rect
        x="0.5"
        y="0.5"
        width="85"
        height="47"
        rx="3.5"
        fill="#00A460"
        fill-opacity="0.05"
      />
      <path
        d="M28.8749 30.9999L23.0749 22.2249L28.8249 13.3999H24.5999L20.7499 19.3999L16.9499 13.3999H12.5749L18.3249 22.1499L12.5249 30.9999H16.7999L20.6499 24.9749L24.5249 30.9999H28.8749Z"
        fill="#23E191"
      />
      <path
        d="M36.2608 17.775C32.8858 17.775 30.9608 19.625 30.6108 21.85H34.0108C34.2108 21.175 34.8608 20.6 36.1108 20.6C37.3358 20.6 38.2358 21.225 38.2358 22.425V23.025H35.4108C32.1108 23.025 30.1108 24.55 30.1108 27.2C30.1108 29.8 32.1108 31.3 34.6108 31.3C36.2858 31.3 37.6358 30.65 38.4358 29.6V31H41.8108V22.55C41.8108 19.4 39.5858 17.775 36.2608 17.775ZM35.6108 28.475C34.4858 28.475 33.7608 27.925 33.7608 27.025C33.7608 26.1 34.5608 25.55 35.6858 25.55H38.2358V26.125C38.2358 27.625 37.0108 28.475 35.6108 28.475Z"
        fill="#23E191"
      />
      <path
        d="M51.925 18.3C50.125 18.3 48.65 18.95 47.7 20.275C47.95 17.85 48.925 16.65 51.375 16.275L56.3 15.55V12.375L51.025 13.25C45.925 14.1 44.35 17.5 44.35 23.175C44.35 28.275 46.825 31.3 51.2 31.3C55.125 31.3 57.55 28.3 57.55 24.7C57.55 20.825 55.3 18.3 51.925 18.3ZM51.025 28.25C49.125 28.25 48.075 26.725 48.075 24.675C48.075 22.7 49.175 21.275 51.05 21.275C52.875 21.275 53.9 22.75 53.9 24.7C53.9 26.65 52.9 28.25 51.025 28.25Z"
        fill="#23E191"
      />
      <path
        d="M67.2855 17.775C65.3855 17.775 64.1605 18.6 63.5105 19.575V18.075H60.0105V35.775H63.6605V29.75C64.3105 30.65 65.5355 31.3 67.1855 31.3C70.3605 31.3 72.8855 28.8 72.8855 24.55C72.8855 20.225 70.3605 17.775 67.2855 17.775ZM66.4105 28.15C64.7105 28.15 63.5855 26.775 63.5855 24.6V24.5C63.5855 22.325 64.7105 20.925 66.4105 20.925C68.1105 20.925 69.2105 22.35 69.2105 24.55C69.2105 26.75 68.1105 28.15 66.4105 28.15Z"
        fill="#23E191"
      />
      <rect
        x="0.5"
        y="0.5"
        width="85"
        height="47"
        rx="3.5"
        stroke="url(#paint0_linear_12:37463)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_12:37463"
        x1="-9.81522"
        y1="29"
        x2="97.2174"
        y2="29"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#23E191" stop-opacity="0.9" />
        <stop offset="0.520349" stop-color="#181818" />
        <stop offset="1" stop-color="#21EB96" stop-opacity="0.9" />
        <stop offset="1" stop-color="#23E191" stop-opacity="0.9" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconHabr',
  components: {},
  data() {
    return {};
  },
};
</script>
