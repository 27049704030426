import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';

// import Outro from '@/components/Outro.vue';
// import RandomCofee from '@/components/RandomCofee.vue';
import GameFlow from '@/components/GameFlow.vue';

import GamePage from '../pages/GamePage.vue';
import LandingPage from '../pages/LandingPage.vue';
import UiPage from '../pages/UI.vue';

Vue.use(VueRouter);
const uriParams = new URLSearchParams(window.location.search);
const authToken = uriParams.get('token');
const needAuth = async (to, from, next) => {
  console.log('[router-needAuth] start');
  if (store.getters.token) {
    console.log('[router-needAuth] Token found!');
    store.commit('user/SET_TOKEN', store.getters.token);
    store.commit('user/SET_INTRO_SHOWN', true);
    await store.dispatch('user/fetchUser');
    await store.dispatch('game/fetchGame');
    // next();
  }
  next();
  // else {
  //   console.log('[router-needAuth] Token NOT_FOUND');
  //   next('/');
  // }
};
const publicPage = async (to, from, next) => {
  console.log('[router-publicPage] start', to);
  if (store.getters.token) {
    console.log('[router-publicPage] Token found!');
    store.commit('user/SET_TOKEN', store.getters.token);
    next('/game');
  } else {
    console.log('[router-publicPage] Token NOT_FOUND');
    next();
  }
};

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage,
    beforeEnter: publicPage,
  },
  {
    path: '/ui',
    name: 'UiPage',
    component: UiPage,
  },
  {
    path: '/game',
    // name: 'GamePage',
    component: GamePage,
    beforeEnter: needAuth,
    meta: {
      bodyClass: 'game-layout--bg',
    },
    children: [
      {
        path: '',
        name: 'GamePage',
        component: GameFlow,
      },
      // {
      //   path: 'outro',
      //   component: Outro,
      //   beforeEnter: (to, from, next) => {
      //     store.commit('SET_IS_HUD_SHOW', false);
      //     next();
      //   },
      //   meta: {
      //     bodyClass: 'game-layout--theend',
      //   },
      // },
      // {
      //   path: 'random-cofee',
      //   component: RandomCofee,
      //   beforeEnter: (to, from, next) => {
      //     store.commit('SET_IS_HUD_SHOW', false);
      //     next();
      //   },
      //   meta: {
      //     bodyClass: 'game-layout--theend',
      //   },
      // },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
