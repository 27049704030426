<!-- eslint-disable max-len -->
<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.8">
      <rect
        x="0.5"
        y="0.5"
        width="47"
        height="47"
        rx="3.5"
        fill="#00A460"
        fill-opacity="0.05"
      />
      <path
        d="M34.7746 18.3763C34.5154 17.4124 33.7554 16.6526 32.7916 16.393C31.0309 15.9113 23.988 15.9113 23.988 15.9113C23.988 15.9113 16.9454 15.9113 15.1847 16.3747C14.2394 16.634 13.4609 17.4125 13.2016 18.3763C12.7383 20.1369 12.7383 23.788 12.7383 23.788C12.7383 23.788 12.7383 27.4576 13.2016 29.1998C13.4612 30.1635 14.2209 30.9234 15.1848 31.1829C16.9639 31.6648 23.9883 31.6648 23.9883 31.6648C23.9883 31.6648 31.0309 31.6648 32.7916 31.2013C33.7555 30.9419 34.5154 30.1821 34.7749 29.2183C35.2381 27.4576 35.2381 23.8066 35.2381 23.8066C35.2381 23.8066 35.2567 20.1369 34.7746 18.3763ZM21.7457 27.1611V20.415L27.6022 23.788L21.7457 27.1611Z"
        fill="#23E191"
      />
      <rect
        x="0.5"
        y="0.5"
        width="47"
        height="47"
        rx="3.5"
        stroke="url(#paint0_linear_12:37455)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_12:37455"
        x1="-5.47826"
        y1="29"
        x2="54.2609"
        y2="29"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#23E191" stop-opacity="0.9" />
        <stop offset="0.520349" stop-color="#181818" />
        <stop offset="1" stop-color="#21EB96" stop-opacity="0.9" />
        <stop offset="1" stop-color="#23E191" stop-opacity="0.9" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconYoutube',
  components: {},
  data() {
    return {};
  },
};
</script>
