<template>
  <div class="hud" style="margin-top: 40px;">
    <div class="hud-content">
      <div class="level__container">
        <div
          class="level__item"
          :class="{ current: i <= currentStage }"
          v-for="i in numberOfLevels"
          :key="`lvl-${i}`"
        >
          {{ i }}
        </div>
      </div>
      <counter v-if="gameInfo && gameInfo.serial === 1" />
    </div>
  </div>
</template>

<script>
import Counter from './UI/Counter.vue';

export default {
  components: { Counter },
  name: 'HudPanel',
  data() {
    return {
      numberOfLevels: 4,
    };
  },
  computed: {
    currentStage() {
      return this.$store.state.game.currentStage;
    },
    gameInfo() {
      return this.$store.state.game.gameInfo;
    },
  },
  mounted() {},
  props: {},
};
</script>

<style lang="scss">
.hud {
  @apply absolute top-28 text-green;
  width: 100%;
  &-content {
    @apply flex justify-between;
  }
}
.level {
  &__container {
    @apply relative;
    margin: 0 -1px 0 -1px;
    height: 32px;
    &:after {
      @apply absolute leading-16;
      font-size: 8px;
      bottom: calc(100% + 2px);
      width: max-content;
      content: 'УРОВЕНЬ';
      left: 0;
    }
  }
  &__item {
    @apply inline-block text-14 leading-32 font-croc-bold text-gray-300 rounded-2 text-center
      align-top;
    background: linear-gradient(
        0deg,
        rgba(104, 103, 108, 0.1),
        rgba(104, 103, 108, 0.1)
      ),
      #101010;
    --size: 32px;
    width: var(--size);
    height: var(--size);
    margin: 1px;
    &.current {
      @apply text-green;
      background: linear-gradient(
          0deg,
          rgba(0, 164, 96, 0.1),
          rgba(0, 164, 96, 0.1)
        ),
        #101010;
    }
  }
}
</style>
