<template>
  <div class="geecko-enter">
    <Card ref="mainCard">
      <Dialog
        withNpc
        :class="`${'without-border'}`"
      >
        <div>
          <h3 class="text-18 leading-28 mb-8">Просыпайся</h3>
          <p class="text-16 leading-26 font-croc-regular">
            Нужно авторизоваться, и я покажу тебе насколько глубока кроличья нора
          </p>
        </div>
      </Dialog>
    </Card>
    <GeeckoIdButton ref="enterButton" type="link" v-if="showButton" />
  </div>
</template>

<script>
import gsap from 'gsap';

import GeeckoIdButton from '@/components/UI/GeeckoIdButton.vue';
import Dialog from './Dialog.vue';
import Card from './Card.vue';

export default {
  data() {
    return {
      showButton: false,
    };
  },
  components: { Card, Dialog, GeeckoIdButton },
  name: 'GeeckoEnter',
  props: {},
  mounted() {
    this.$refs.mainCard.appear(() => {
      this.showButton = true;
      this.$refs.mainCard.flip();
      this.$nextTick(() => {
        gsap.to(this.$refs.enterButton.$el, {
          duration: 1,
          autoAlpha: 1,
        });
      });
    });
  },
};
</script>

<style lang="scss">
.geecko-enter {
  @apply z-0 text-center pb-72 px-12 pt-8 flex flex-col justify-center relative
    bg-no-repeat;
  background-size: cover;
  background-position: top, top, bottom, top;
  background-image: url('/images/game-matrix.png'),
    linear-gradient(
      0deg,
      rgba(16, 16, 16, 0) 0%,
      rgba(16, 16, 16, 0.76) 50%,
      #101010 100%
    ),
    linear-gradient(
      180deg,
      rgba(16, 16, 16, 0) 0%,
      rgba(16, 16, 16, 0.76) 50%,
      #101010 100%
    ),
    url('/images/programmer-bg.png');
  height: 100%;
  & a.croc-button {
    @apply absolute bottom-16;
    width: calc(100% - 24px);
  }
}
</style>
