<template>
  <div class="outro-dialog">
    <div class="flex-1"></div>
    <Card v-if="showDialog" ref="mainCard">
      <Dialog class="outro-dialog__dialog without-border" with-npc>
        <div class="outro-dialog__dialog-content" style="margin-top: 100px;">
          <div class="outro-dialog__dialog-header">
            Просыпайся
          </div>
          <div class="outro-dialog__dialog-text">
            Матрица прорвана, а это значит, что ты сможешь вернуться
            к привычным делам, но сперва - таблица лидеров!
          </div>
        </div>
      </Dialog>
    </Card>
    <div class="flex-1"></div>
    <div v-if="showButtons" class="outro-dialog__button-container">
      <Button ref="button" is-next is-addon-colored is-full-width @click="click">
        Продолжить
      </Button>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';

import Card from '@/components/Card.vue';
import Dialog from '@/components/Dialog.vue';
import Button from '@/components/UI/Button.vue';

export default {
  data() {
    return {
      showDialog: false,
      showButtons: false,
    };
  },
  components: {
    Dialog,
    Button,
    Card,
  },
  methods: {
    show() {
      this.showDialog = true;
      this.$nextTick(() => {
        this.$refs.mainCard.appear(() => {
          setTimeout(() => {
            this.$refs.mainCard.flip();
            this.showButtons = true;
            this.$nextTick(() => {
              gsap.to(this.$refs.button.$el, {
                duration: 1,
                autoAlpha: 1,
              });
            });
          }, 250);
        });
      });
    },
    click() {
      gsap.to(this.$refs.button.$el, {
        duration: 1,
        autoAlpha: 0,
      });
      this.$refs.mainCard.disappear(() => {
        this.$emit('click');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.outro-dialog {
  @apply relative flex flex-col justify-between h-full;
  &__dialog {
    @apply flex flex-col items-center h-240;
  }
  &__dialog-content {
    @apply flex flex-col items-center text-center;
  }
  &__dialog-header {
    @apply text-18 leading-28 uppercase font-croc-bold;
  }
  &__dialog-text {
    @apply text-16 leading-26 font-croc-regular mt-8;
  }
  &__button-container {
    @apply bg-black pb-16;
  }
}
</style>
