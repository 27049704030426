<template>
  <div class="landing relative text-left">
    <div id="acab"
    style="background: url('/images/cursor-under.svg'); transform: translate(-50%, -50%);
    width: 1000px; opacity: 1; height: 1000px; background-size: contain;
    "
    class="absolute bg-center bg-contain bg-no-repeat top-0 left-0"
    >
    </div>
    <div class="mask br"></div>
    <div class="landing-attention">
      <div class="w-288">
        <Dialog>
          <div class="text-center">
            <icon-rotation />
            <h3 class="text-red text-18 leading-28 mb-8 mt-24 uppercase">
              Переверни обратно
            </h3>
            <p class="text-16 leading-24 font-croc-light">
              Дружище, так не пойдет. Если хочешь продолжить играть, верни
              телефон в вертикальное положение
            </p>
          </div>
        </Dialog>
      </div>
    </div>
    <div class="container mx-auto pb-28 relative">
      <div class="flex justify-between flex-wrap sm:flex-nowrap">
        <span class="croc-icon order-1 sm:order-0"><icon-croc /></span>
        <img
          class="landing-matrix-1 order-1 sm:order-0"
          style="margin-left: 200px;"
          src="/images/analyst.png"
          alt=""
        />
        <div class="header-conf-info order-0 sm:order-1">
          <div class="header-conf-info__content">
            <span>11 ошибок аналитиков при сборе требований</span>
            <span class="header-conf-info__content-time">19.11 15:30</span>
            <!-- <span>Малый зал</span> -->
          </div>
        </div>
      </div>
      <h1 class="landing-title text-left sm:text-left">
        Очнись, выйди за <br />
        рамки виртуальности
      </h1>
      <p class="landing-subtitle text-left sm:text-left">
        Пройди квиз для аналитиков, оцени мощь своих скиллов<br />
        и участвуй в розыгрыше призов. Вызов, с которым<br />
        справиться только настоящий избранный
      </p>
      <div class="flex flex-row items-center mb-76">
        <a
          href="#game"
          @click.prevent="goToGame"
          class="btn btn--green btn--size-lg uppercase  btn-hover"
        >
          <p>Начать игру</p>
          <span></span>
        </a>
        <div class="clock-container">
          <div class="clock" />
          <span>Игра закончится 20 ноября 18:00<br />Объявим победителей в 20:00</span>
        </div>
      </div>
      <div class="question" ref="question">
        <div class="question__content">
          <div class="question__content-answer cursor-pointer" @click.prevent="goToGame">
            <p class="text-white">Ты спишь</p>
            <span style="padding: 20px;
            border: 1px solid rgba(35, 225, 145, 0.4);">пробудиться</span>
            <div class="question__content-phone"></div>
          </div>
        </div>
      </div>
      <landing-prizes />
    </div>
    <!-- <div class="game-container">
      <div class="game" id="game" ref="game">
        <intro v-if="stage === 'intro'" />
        <geecko-enter v-else-if="stage === 'enter'" />
        <game v-else-if="stage === 'game'" />
        <game-result v-if="false" />
      </div>
    </div> -->
    <div
      class=" w-full flex flex-row justify-center pt-32"
      style="padding: 0px 10px;"
    >
      <div class="w-full flex flex-col justify-between
      sm:py-40 lg:flex-row flex-col-reverse"
      style="max-width: 1300px;">
        <div class="landing__image mt-0 sm:mt-0"></div>
        <div>
          <h2 class="landing-h3">Пробудившиеся</h2>
          <div style="" class="sm:flex justify-between relative z-10">
            <div class="table-container">
              <top-table />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container mx-auto xl:pt-0 pb-72 lg:pb-108">
      <h1 style="margin-bottom: 48px;">Доклады на Analyst Days’13</h1>
      <landing-mobius />
    </div>
    <div class="container mx-auto pt-39 pb-40 xl:pb-128">
      <landing-about-croc />
    </div>
    <div class="footer-container">
      <div class="container mx-auto bg-none">
        <footer-content />
      </div>
    </div>
  </div>
</template>

<script>
// import { TimelineMax, Quad } from 'gsap';

// import Game from '../components/Game.vue';
// import GameResult from '../components/GameResult.vue';
// import GeeckoEnter from '../components/GeeckoEnter.vue';
import IconCroc from '../components/Icons/IconCroc.vue';
import FooterContent from '../components/LandingPage/FooterContent.vue';
import LandingAboutCroc from '../components/LandingPage/LandingAboutCroc.vue';
import LandingMobius from '../components/LandingPage/LandingMobius.vue';
import LandingPrizes from '../components/LandingPage/LandingPrizes.vue';
import TopTable from '../components/LandingPage/TopTable.vue';
// import Intro from '../components/Intro.vue';
import Dialog from '../components/Dialog.vue';
import IconRotation from '../components/Icons/IconRotation.vue';
// import IconJS from '../components/Icons/IconJS.vue';
// import CanvasBackground from '../components/CanvasBackground.vue';

export default {
  components: {
    IconCroc,
    // Game,
    LandingPrizes,
    LandingMobius,
    LandingAboutCroc,
    FooterContent,
    TopTable,
    // GameResult,
    // GeeckoEnter,
    // Intro,
    Dialog,
    IconRotation,
    // IconJS,
  },
  name: 'LandingPage',
  data() {
    return {
      isDev: process.env.NODE_ENV === 'development',
      evY: null,
      evX: null,
    };
  },
  computed: {
    user() {
      return this.$store.getters['user/user'];
    },
    stage() {
      if (!this.user?.id) {
        return this.isIntroShown ? 'enter' : 'intro';
      }
      return 'game';
    },
    isIntroShown() {
      return this.$store.state.user.isIntroShown;
    },
  },
  methods: {
    async resetGame() {
      await this.$store.dispatch('game/restartGame');
      window.location.reload();
    },
    // goToGame() {
    //   document.getElementById('game').scrollIntoView({
    //     behavior: 'smooth',
    //   });
    // },
    moveMouse(e) {
      const x = e.pageX - 100;
      const y = e.pageY - 100;

      const mask = document.querySelector('.mask');
      // const landing = document.querySelector('.landing');
      mask.style.top = `${y}px`;
      mask.style.left = `${x}px`;
      mask.style.opacity = '1';
    },
    goToGame() {
      this.$router.push('/game');
    },
    onMouseMove(ev) {
      document.getElementById('acab').style.transform = `translateY(${(window.scrollY + ev.clientY - 500)}px)`;
      this.evY = ev.clientY;
      this.evX = ev.clientX;
      document.getElementById('acab').style.transform += `translateX(${(ev.clientX - 500)}px)`;
    },
    onScroll() {
      document.getElementById('acab').style.transform = `translateY(${(window.scrollY + this.evY - 500)}px)`;
      document.getElementById('acab').style.transform += `translateX(${(this.evX - 500)}px)`;
    },
  },
  mounted() {
    window.addEventListener('mousemove', this.onMouseMove, false);
    window.addEventListener('scroll', this.onScroll);
    // скролл к игре при перезагрузке страницы для авторизованных
    // if (this.user && this.user.id) {
    //   this.$refs.game.scrollIntoView();
    // }
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    // const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    // this.$refs.game.style.setProperty('--vh', `${vh}px`);
    // We listen to the resize event
    // window.addEventListener('resize', () => {
    //   // We execute the same script as before
    //   const newVh = window.innerHeight * 0.01;
    //   this.$refs.game.style.setProperty('--vh', `${newVh}px`);
    // });
  },
  async beforeDestroy() {
    console.log('[landingPage] beforeDestroy');
    window.removeEventListener('mousemove', this.onMouseMove, false);
    window.removeEventListener('scroll', this.onScroll);
  },
};
</script>

<style lang="scss">
.clock-container {
  @apply flex flex-row items-center;
  font-size: 14px;
  margin-left: 35px;
  line-height: 120%;
  color: #3D9872;
  .clock {
    background: url('/images/clock.svg');
    margin-right: 14px;
    width: 24px;
    height: 24px;
    @apply bg-center bg-no-repeat bg-contain;
  }
}
.container {
  @apply relative z-10;
}
.landing {
  @apply bg-gray-900 leading-56 text-36 text-white font-croc-bold;
  width: 100%;
  overflow: hidden;
  background-image: url('/images/pattern.png');
  background-size: 1280px;
  background-repeat: repeat;
  image-rendering: optimizeSpeed;             /*                     */
  image-rendering: -moz-crisp-edges;          /* Firefox             */
  image-rendering: -o-crisp-edges;            /* Opera               */
  image-rendering: -webkit-optimize-contrast; /* Chrome (and Safari) */
  image-rendering: pixelated;                 /* Chrome as of 2019   */
  image-rendering: optimize-contrast;         /* CSS3 Proposed       */
  -ms-interpolation-mode: nearest-neighbor;   /* IE8+                */
}
.landing-h2 {
  @apply sm:text-32 sm:leading-48 mb-56;
  font-weight: bold;
  font-size: 26px;
  line-height: 120%;
}
.landing-h3 {
  @apply text-24 leading-34 sm:text-32 sm:leading-48 mb-24;
  margin-top: -100px;
}

@screen sm {
  .landing-h2 {
    font-size: 52px;
    font-weight: bold;
    line-height: 100%;
  }
}
button {
  &:hover {
    opacity: 0.6 !important;
  }
}
a {
  &:hover {
    opacity: 0.6 !important;
  }
}
.landing__image {
  max-width: 600px;
  width: 100%;
  height: 400px;
  // margin-top: -100px;
  margin-top: 50px !important;
  margin-bottom: 70px;
  background-image: url('/images/idk-staff.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}
@media screen and (max-width: 1280px) {
  .landing-h3 {
    margin-top: 0px;
  }
  .landing__image {
    margin-top: 50px !important;
  }
}
.croc-icon {
  @apply pt-16 lg:pt-24;
  & svg {
    width: 228px;
  }
}
@media (max-width: 768px) {
  .croc-icon {
    & svg {
      width: 170px;
    }
  }
}
.landing-title {
  @apply text-24 leading-32 sm:text-36 sm:leading-56 lg:text-42 lg:leading-64
          xl:text-48 mt-40 sm:mt-112;
}
.landing-subtitle {
  @apply mt-16 mb-24 sm:mb-44 text-16 leading-24 sm:text-18 sm:leading-28 lg:text-22 lg:leading-32
    font-croc-regular;
  color: #c9c9c9;
  & strong {
    color: #fff;
  }
}

.btn {
  @apply text-14 text-white border-green leading-38 px-32
    inline-block w-full sm:w-max text-center relative;
  transition: 0.25s;
  border-width: 1px;
  z-index: 1;
  &:active,
  &:focus {
    outline: none;
  }
  &:hover:not(:after) {
    @apply text-white;
  }
  &:hover:active {
    @apply text-white;
  }
  &:hover {
    opacity: 1 !important;
  }
  &:disabled {
    @apply border-gray-300 text-gray-300 cursor-not-allowed;
    background: rgba(104, 103, 108, 0.15);
  }
  &--size-lg {
    @apply leading-44 text-16;
    &:hover {
      background: #00a460;
      color: white;
      & span {
        display: none;
      }
    }
    & span {
      width: 80px;
      height: 35px;
      position: absolute;
      top: 5px;
      left: 50%;
      z-index: -2;
      transform: translatex(-50%);
      border-radius: 50%;
      background-color: rgba(16, 16, 16, 1);
      -webkit-box-shadow: 5px 5px 10px 10px rgba(16, 16, 16, 1);
      box-shadow: 0px 0px 10px 10px rgba(16, 16, 16, 1);
    }
    &:after {
      content: '●';
      position: absolute;
      right: -5px;
      top: -22px;
    }
    & p:after {
      content: '●';
      position: absolute;
      right: -5px;
      bottom: -22px;
    }
    &:before {
      content: '●';
      position: absolute;
      left: -5px;
      top: -22px;
    }
    & p:before {
      content: '●';
      position: absolute;
      left: -5px;
      bottom: -22px;
    }
  }
  &--red {
    @apply text-red border-red;
    background: transparent;
    &:hover:not(:disabled) {
      @apply text-white;
      background: #ff645a;
    }
  }
  &--white {
    @apply text-white border-white;
    background: transparent;
    width: auto;
    &:hover:not(:disabled) {
      @apply text-white;
    }
    & span {
      width: 60px;
      height: 30px;
      position: absolute;
      top: 5px;
      left: 50%;
      z-index: -2;
      transform: translatex(-50%);
      border-radius: 50%;
      background-color: rgba(27, 27, 27, 1);
      -webkit-box-shadow: 5px 5px 10px 10px rgba(27, 27, 27, 1);
      box-shadow: 0px 0px 10px 10px rgba(27, 27, 27, 1);
    }
    &:after {
      content: '●';
      position: absolute;
      right: -4px;
      top: -19px;
    }
    & p:after {
      content: '●';
      position: absolute;
      right: -4px;
      bottom: -19px;
    }
    &:before {
      content: '●';
      position: absolute;
      left: -4px;
      top: -19px;
    }
    & p:before {
      content: '●';
      position: absolute;
      left: -4px;
      bottom: -19px;
    }
  }
  &--green {
    @apply text-green border-green;
  }
  &--blue {
    @apply text-blue border-blue;
    background: rgba(71, 93, 235, 0.2);
    &:hover:not(:disabled) {
      background: linear-gradient(
          269.82deg,
          rgba(71, 93, 235, 0.3) 1.94%,
          rgba(71, 93, 235, 0) 88.58%
        ),
        #101010;
    }
    &:hover:active {
      @apply text-white;
      background: linear-gradient(0deg, #475deb, #475deb), #101010;
    }
  }
  &--tg {
    &:before {
      content: '';
      @apply absolute bg-contain;
      width: 16px;
      height: 16px;
      top: calc(50% - 16px / 2);
      left: 8px;
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.99577 0.333313C5.67723 0.333313 4.3883 0.724306 3.29197 1.45685C2.19564 2.18939 1.34116 3.23058 0.836574 4.44876C0.33199 5.66693 0.199968 7.00738 0.457203 8.30058C0.714437 9.59379 1.34938 10.7817 2.28173 11.714C3.21408 12.6464 4.40196 13.2813 5.69517 13.5385C6.98838 13.7958 8.32882 13.6638 9.54699 13.1592C10.7652 12.6546 11.8064 11.8001 12.5389 10.7038C13.2714 9.60745 13.6624 8.31852 13.6624 6.99998C13.6625 6.12448 13.4901 5.25754 13.1551 4.44867C12.8201 3.6398 12.3291 2.90484 11.71 2.28577C11.0909 1.6667 10.3559 1.17564 9.54708 0.840635C8.73821 0.505629 7.87127 0.33324 6.99577 0.333313ZM9.11544 10.435C9.09049 10.4972 9.05252 10.5533 9.00409 10.5997C8.95565 10.646 8.89787 10.6815 8.83461 10.7037C8.77135 10.7259 8.70408 10.7343 8.63731 10.7284C8.57053 10.7224 8.5058 10.7023 8.44744 10.6693L6.63766 9.26304L5.47612 10.3346C5.44917 10.3546 5.41764 10.3674 5.38444 10.3719C5.35123 10.3764 5.31742 10.3726 5.28612 10.3606L5.50878 8.3683L5.51591 8.37395L5.52046 8.33462C5.52046 8.33462 8.77713 5.36961 8.9098 5.24329C9.04411 5.11729 8.9998 5.08995 8.9998 5.08995C9.00744 4.93627 8.75878 5.08995 8.75878 5.08995L4.44377 7.86598L2.64678 7.25398C2.64678 7.25398 2.37078 7.15498 2.34478 6.93731C2.31744 6.72131 2.65577 6.60398 2.65577 6.60398L9.80044 3.76564C9.80044 3.76564 10.3878 3.50396 10.3878 3.93731L9.11544 10.435Z' fill='url(%23paint0_linear)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='0.329101' y1='13.6667' x2='13.6624' y2='0.999984' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2300A460'/%3E%3Cstop offset='1' stop-color='%2373E553'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
    }
  }
}

.tg-btn {
  @apply text-12 rounded-full border border-green leading-32 pr-8 pl-28 relative
    inline-block;
  background: rgba(0, 164, 96, 0.2);
  &:after {
    content: '';
    @apply absolute bg-contain;
    width: 13px;
    height: 13px;
    top: calc(50% - 13px / 2);
    left: 9px;
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.99577 0.333313C5.67723 0.333313 4.3883 0.724306 3.29197 1.45685C2.19564 2.18939 1.34116 3.23058 0.836574 4.44876C0.33199 5.66693 0.199968 7.00738 0.457203 8.30058C0.714437 9.59379 1.34938 10.7817 2.28173 11.714C3.21408 12.6464 4.40196 13.2813 5.69517 13.5385C6.98838 13.7958 8.32882 13.6638 9.54699 13.1592C10.7652 12.6546 11.8064 11.8001 12.5389 10.7038C13.2714 9.60745 13.6624 8.31852 13.6624 6.99998C13.6625 6.12448 13.4901 5.25754 13.1551 4.44867C12.8201 3.6398 12.3291 2.90484 11.71 2.28577C11.0909 1.6667 10.3559 1.17564 9.54708 0.840635C8.73821 0.505629 7.87127 0.33324 6.99577 0.333313ZM9.11544 10.435C9.09049 10.4972 9.05252 10.5533 9.00409 10.5997C8.95565 10.646 8.89787 10.6815 8.83461 10.7037C8.77135 10.7259 8.70408 10.7343 8.63731 10.7284C8.57053 10.7224 8.5058 10.7023 8.44744 10.6693L6.63766 9.26304L5.47612 10.3346C5.44917 10.3546 5.41764 10.3674 5.38444 10.3719C5.35123 10.3764 5.31742 10.3726 5.28612 10.3606L5.50878 8.3683L5.51591 8.37395L5.52046 8.33462C5.52046 8.33462 8.77713 5.36961 8.9098 5.24329C9.04411 5.11729 8.9998 5.08995 8.9998 5.08995C9.00744 4.93627 8.75878 5.08995 8.75878 5.08995L4.44377 7.86598L2.64678 7.25398C2.64678 7.25398 2.37078 7.15498 2.34478 6.93731C2.31744 6.72131 2.65577 6.60398 2.65577 6.60398L9.80044 3.76564C9.80044 3.76564 10.3878 3.50396 10.3878 3.93731L9.11544 10.435Z' fill='url(%23paint0_linear)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='0.329101' y1='13.6667' x2='13.6624' y2='0.999984' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2300A460'/%3E%3Cstop offset='1' stop-color='%2373E553'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  }
}
.header-conf-info {
  @apply w-full sm:w-max sm:mt-0 flex sm:border-t-0 rounded-bl-20
    rounded-br-20 rounded-t-20 sm:rounded-t-0 relative;
  &__content {
    @apply w-full sm:w-max relative py-12 sm:py-16 pr-12 lg:pr-24 pl-56 sm:pl-64 text-12 lg:text-14
      leading-24 uppercase flex sm:block flex-col;
    z-index: 9;
    background: rgba(27, 27, 27, 1);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    &-time {
      @apply text-green sm:px-24 order-first;
    }
  }
  &:after {
    content: '';
    @apply absolute w-full h-full bg-gray-900 top-0 left-0 rounded-bl-20 rounded-br-20
      rounded-t-20 sm:rounded-t-0;
    z-index: 0;
  }
}
@screen sm {
  .header-conf-info {
    left: 10px;
    border-bottom-right-radius: 0;
    border-right: none;
  }
}
@screen lg {
  .header-conf-info {
    left: 0;
    border-bottom-right-radius: 20px;
  }
}
/* eslint-disable max-len */
.question {
  transform-origin: center bottom;
  color: rgba(35, 225, 145, 1);
  // border: 1px solid red;
  @apply relative leading-32 flex w-full justify-center  xl:block xl:leading-24 -top-40 lg:top-120;
  @apply right-0 text-center rounded-24 xl:w-max lg:justify-end lg:absolute mb-40 mx-auto;
  max-width: 100%;
  &__content {
    @apply w-full h-400;
    max-width: 400px;
    width: 100%;
    // border: 1px solid red;
    border-radius: inherit;
    background-image: url('/images/user-img.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    &-answer {
      @apply absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);

      font-weight: bold;
      font-size: 15px;
      line-height: 100%;
      text-transform: uppercase;
      &:hover {
        color: white;
        transform: scale(1.01) translateX(-49%);
      }
      & span {
        display: inline-block;
        margin-top: 15px;
        font-size: 24px;
      }
    }
    &-phone {
      width: 150px;
      height: 100px;
      margin: 0 auto;
      background-image: url('/images/user-phone.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      cursor: pointer;
    }
  }
}
@screen lg {
  .question {
    @apply top-160;
    width: 100% !important;
    max-width: 560px !important;
    &__content {
      @apply w-560 h-560;
      max-width: 560px;
      width: 100%;
      background-size: 100%;
    }
  }
}

.landing-top-prizes {
  @apply w-full sm:w-312 xl:pr-168 mt-48 sm:mt-0;
  box-sizing: content-box;
}
.table-container {
  @apply flex-auto;
}
.landing-matrix-1 {
  @apply lg:block xl:left-548;
  // height: 40px;
  // width: 52px;
  margin: auto 0;
}
.landing-matrix-2 {
  @apply absolute bottom-0 hidden lg:block;
  width: 148px;
  right: 224px;
  z-index: 9;
}
.footer-container {
  @apply bg-black;
}

.landing-attention {
  @apply justify-center items-center;
  display: none;
  position: fixed;
  width: 100vw;
  overflow: hidden;
  // border: 1px solid red;
  height: 100vh;
  background-color: #101010;
  background-image: linear-gradient(90deg, #101010 0%, rgba(16, 16, 16, 0) 100%),
    url('/images/landing-matrix-horizontal.svg');
  background-size: 46px, 80px;
  background-position: left;
  background-repeat: no-repeat repeat;
  z-index: 9999;
  & svg {
    @apply mx-auto;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  .landing-attention {
    display: flex;
  }
}

// Mask
.mask {
  position: absolute;
  height: 250px;
  width: 250px;
  opacity: 0;
  border-radius: 50%;
  background-image: url('https://w7.pngwing.com/pngs/465/869/png-transparent-halftone-monochrome-circle-pattern-white-text-photography.png');
  background-repeat: no-repeat;
  background-size: cover;
  mix-blend-mode: multiply;
}
</style>
