<template>
    <div class="intro">
        <div class="intro__bg" />
        <transition name="fade">
          <div v-if="!isAwaken" class="intro__header">
              {{
          conferenceHasNotStarted
            ? 'Игра станет доступна, когда начнется конференция'
            : 'Ты спишь?'
        }}
          </div>
        </transition>
        <transition name="fade">
          <div v-if="!isAwaken" class="intro__buttons">
            <div
          v-if="conferenceHasNotStarted"
          class="text-green text-12 leading-18 mb-12 text-center"
        >
          Конференция начнется через
        </div>
            <Button
              is-uppercase
              is-full-width is-has-bg is-text-colored @click="awake"
              :disabled="conferenceHasNotStarted"><Timer
            :interval="timeBeforeGame"
            withHours
            v-if="conferenceHasNotStarted"
          /><template v-else> Пробудиться </template></Button>
          </div>
        </transition>
    </div>
</template>

<script>
import Button from '@/components/UI/Button.vue';
import Timer from './UI/Timer.vue';

const AWAKE_TIME = 2000;

export default {
  data() {
    return {
      isAwaken: false,
    };
  },
  components: {
    Button,
    Timer,
  },
  methods: {
    awake() {
      this.isAwaken = true;
      setTimeout(() => {
        console.log('intor!');
        this.$store.commit('user/SET_INTRO_SHOWN', true);
      }, AWAKE_TIME);
    },
    /* stay() {
      this.isAwaken = true;
      setTimeout(() => {
        this.isAwaken = false;
      }, AWAKE_TIME);
    }, */
  },
  computed: {
    timeBeforeGame() {
      return this.$store.getters['game/timeBeforeGame'];
    },
    conferenceHasNotStarted() {
      return this.timeBeforeGame > 0;
    },
  },
  mounted() {
    console.log('timeBeforeGame', this.timeBeforeGame);
  },
};
</script>

<style lang="scss" scoped>
.intro {
  @apply relative h-full flex flex-col text-white justify-between;
  &__bg {
    @apply w-full h-full absolute left-0 top-0;
    background-image: url('/images/intro_1.png');
    z-index: 0;
    width: calc(100% + 32px);
    left: -16px;
    background-size: cover;
    background-position: center center;
    z-index: 0;
  }
  &__header {
    @apply relative mt-32 text-32 leading-48 font-croc-bold uppercase text-center;
    z-index: 1;
  }

  &__buttons {
    @apply relative mb-24 flex flex-col;
    z-index: 1;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>
