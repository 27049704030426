<template>
  <component :is="tag"
    class="croc-button"
    :type="type"
    :disabled="disabled"
    :href="href"
    :class="{
      'croc-button--icon': !!$slots.icon,
      'croc-button--red': isRed,
      'croc-button--blue': isBlue,
      'croc-button--next': isNext,
      'croc-button--prev': isPrev,
      'croc-button--disabled': disabled,
      'croc-button--colored-text': isTextColored,
      'croc-button--has-bg': isHasBg,
      'croc-button--full-width': isFullWidth,
      'croc-button--half-width': isHalfWidth,
      'croc-button--addon-colored': isAddonColored,
      'croc-button--selected': isSelected,
      'uppercase': isUppercase,
    }"
    @click="$emit('click', $event)"
    @mouseenter="$emit('mouseenter', $event)"
    @mouseleave="$emit('mouseleave', $event)"
  >
    <span class="top-circles"/>
    <span class="bottom-circles"/>
    <span v-if="isPrev" class="croc-button__prepend">
      <!-- eslint-disable -->
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 7.33333H6.27337L7.80671 5.80667C7.86887 5.74451 7.91817 5.67071 7.95181 5.5895C7.98545 5.50829 8.00277 5.42124 8.00277 5.33333C8.00277 5.24543 7.98545 5.15838 7.95181 5.07717C7.91817 4.99595 7.86887 4.92216 7.80671 4.86C7.74455 4.79784 7.67076 4.74853 7.58954 4.71489C7.50833 4.68125 7.42128 4.66394 7.33337 4.66394C7.24547 4.66394 7.15842 4.68125 7.07721 4.71489C6.99599 4.74853 6.9222 4.79784 6.86004 4.86L4.19337 7.52667C4.13268 7.59007 4.0851 7.66483 4.05337 7.74667C3.9867 7.90897 3.9867 8.09103 4.05337 8.25333C4.0851 8.33517 4.13268 8.40993 4.19337 8.47333L6.86004 11.14C6.92202 11.2025 6.99575 11.2521 7.07699 11.2859C7.15823 11.3198 7.24537 11.3372 7.33337 11.3372C7.42138 11.3372 7.50852 11.3198 7.58976 11.2859C7.671 11.2521 7.74473 11.2025 7.80671 11.14C7.86919 11.078 7.91879 11.0043 7.95263 10.9231C7.98648 10.8418 8.00391 10.7547 8.00391 10.6667C8.00391 10.5787 7.98648 10.4915 7.95263 10.4103C7.91879 10.329 7.86919 10.2553 7.80671 10.1933L6.27337 8.66667H14C14.1769 8.66667 14.3464 8.59643 14.4714 8.4714C14.5965 8.34638 14.6667 8.17681 14.6667 8C14.6667 7.82319 14.5965 7.65362 14.4714 7.5286C14.3464 7.40357 14.1769 7.33333 14 7.33333ZM2.00004 2C1.82323 2 1.65366 2.07024 1.52864 2.19526C1.40361 2.32029 1.33337 2.48986 1.33337 2.66667V13.3333C1.33337 13.5101 1.40361 13.6797 1.52864 13.8047C1.65366 13.9298 1.82323 14 2.00004 14C2.17685 14 2.34642 13.9298 2.47145 13.8047C2.59647 13.6797 2.66671 13.5101 2.66671 13.3333V2.66667C2.66671 2.48986 2.59647 2.32029 2.47145 2.19526C2.34642 2.07024 2.17685 2 2.00004 2Z" fill="currentColor"/>
      </svg>
      <!-- eslint-enable -->
    </span>
    <span class="croc-button__text">
      <span v-if="!!$slots.icon" class="croc-button__icon">
        <slot name="icon" />
      </span>
      <slot />
    </span>
    <span v-if="isNext" class="croc-button__append">
      <!-- eslint-disable -->
      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.50002 8.66667L10.2267 8.66667L8.69335 10.1933C8.63119 10.2555 8.58189 10.3293 8.54825 10.4105C8.51461 10.4917 8.49729 10.5788 8.49729 10.6667C8.49729 10.7546 8.51461 10.8416 8.54825 10.9228C8.58189 11.004 8.63119 11.0778 8.69335 11.14C8.75551 11.2022 8.82931 11.2515 8.91052 11.2851C8.99174 11.3187 9.07878 11.3361 9.16669 11.3361C9.25459 11.3361 9.34164 11.3187 9.42285 11.2851C9.50407 11.2515 9.57786 11.2022 9.64002 11.14L12.3067 8.47333C12.3674 8.40993 12.415 8.33517 12.4467 8.25333C12.5134 8.09103 12.5134 7.90897 12.4467 7.74667C12.415 7.66483 12.3674 7.59007 12.3067 7.52667L9.64002 4.86C9.57805 4.79751 9.50431 4.74792 9.42307 4.71407C9.34183 4.68022 9.2547 4.6628 9.16669 4.6628C9.07868 4.6628 8.99154 4.68022 8.9103 4.71407C8.82906 4.74792 8.75533 4.79751 8.69335 4.86C8.63087 4.92198 8.58127 4.99571 8.54743 5.07695C8.51358 5.15819 8.49616 5.24532 8.49616 5.33333C8.49616 5.42134 8.51358 5.50848 8.54743 5.58972C8.58127 5.67096 8.63087 5.74469 8.69335 5.80667L10.2267 7.33333L2.50002 7.33333C2.32321 7.33333 2.15364 7.40357 2.02862 7.52859C1.90359 7.65362 1.83335 7.82319 1.83335 8C1.83335 8.17681 1.90359 8.34638 2.02862 8.4714C2.15364 8.59643 2.32321 8.66667 2.50002 8.66667ZM14.5 14C14.6768 14 14.8464 13.9298 14.9714 13.8047C15.0964 13.6797 15.1667 13.5101 15.1667 13.3333L15.1667 2.66667C15.1667 2.48986 15.0965 2.32029 14.9714 2.19526C14.8464 2.07024 14.6768 2 14.5 2C14.3232 2 14.1536 2.07024 14.0286 2.19526C13.9036 2.32029 13.8334 2.48986 13.8334 2.66667L13.8334 13.3333C13.8334 13.5101 13.9036 13.6797 14.0286 13.8047C14.1536 13.9298 14.3232 14 14.5 14Z" fill="currentColor"/>
      </svg>
      <!-- eslint-enable -->
    </span>
  </component>
</template>

<script>
// eslint-disable-next-line max-len
export default {
  props: {
    isRed: Boolean,
    isBlue: Boolean,
    isTextColored: Boolean,
    isUppercase: Boolean,
    isBigRadius: Boolean,
    isHasBg: Boolean,
    isFullWidth: Boolean,
    isHalfWidth: Boolean,
    isNext: Boolean,
    isPrev: Boolean,
    isAddonColored: Boolean,
    isSelected: Boolean,
    disabled: Boolean,
    type: { type: String, default: 'button' },
    tag: { type: String, default: 'button' },
    href: String,
  },
};
</script>

<style lang="scss" scoped>
.croc-button {
  @apply text-14 text-white relative font-croc-bold leading-18 py-12 px-32;
  --border-color: #03b36a;
  --circles-color: #23E191;
  --circle-size: 8px;
  border-image-slice: 1;
  // background: rgba(0, 164, 96, 0.15);
  --bg: rgba(0, 164, 96, 0.15);
  background: var(--bg);
  border-width: 2px;
  border-style: solid;
  border-image-source:
    linear-gradient(to left,var(--border-color), rgba(0,0,0,0), rgba(0,0,0,0), var(--border-color));
  .top-circles {
    &:before {
      content: '';
      border-radius: 50%;
      background: var(--circles-color);
      width: var(--circle-size);
      height: var(--circle-size);
      position: absolute;
      top: calc(var(--circle-size) / -2);
      left: calc(var(--circle-size) / -2);
    }
    &:after {
      content: '';
      border-radius: 50%;
      background: var(--circles-color);
      width: var(--circle-size);
      height: var(--circle-size);
      position: absolute;
      top: calc(var(--circle-size) / -2);
      right: calc(var(--circle-size) / -2);
    }
  }
  .bottom-circles {
    &:before {
      content: '';
      border-radius: 50%;
      background: var(--circles-color);
      width: var(--circle-size);
      height: var(--circle-size);
      position: absolute;
      bottom: calc(var(--circle-size) / -2);
      left: calc(var(--circle-size) / -2);
    }
    &:after {
      content: '';
      border-radius: 50%;
      background: var(--circles-color);
      width: var(--circle-size);
      height: var(--circle-size);
      position: absolute;
      bottom: calc(var(--circle-size) / -2);
      right: calc(var(--circle-size) / -2);
    }
  }
  &:hover:not(:disabled) {
    background: rgba(0, 164, 96, 0.3);
    @apply text-white;
  }

  &:active {
    --bg: #00A460;
  }

  &:focus {
    @apply outline-none;
  }

  &:disabled {
    --border-color: gray;
    --circles-color: gray;
    @apply bg-gray-300 text-gray-300 bg-opacity-15 cursor-not-allowed;
    &:hover {
      @apply bg-gray-300 text-gray-300 bg-opacity-15
    }
  }
  &--colored-text {
    @apply text-green;

    &.croc-button--blue {
      @apply text-blue;
    }

    &.croc-button--red {
      @apply text-red;
    }
  }

  &--addon-colored {
    .croc-button__append {
      @apply text-green;
    }
  }

  &--blue {
    --border-color: #00AFD6;
    --circles-color: #00AFD6;
    &:hover:not(:disabled) {
      background: linear-gradient(0deg, rgba(71, 93, 235, 0.3), rgba(71, 93, 235, 0.3)), #101010;
      @apply text-white;
    }

    &:active {
      @apply bg-blue;
    }
  }

  &--red {
    --border-color: #FF645A;
    --circles-color: #FF645A;
    &:hover:not(:disabled) {
      background: rgba(255, 100, 90, 0.3);
      @apply text-white;
    }

    &:active {
      --bg: #FF645A;
    }
  }

  &--full-width {
    @apply w-full;
  }

  &--half-width {
    width: 49%;
  }

  &--selected {
    @apply text-white;
    background: #00A460 !important;
  }

  &--has-bg {
    &:not([disabled]) {
      background: linear-gradient(46.47deg, rgba(0, 164, 96, 0.2) 0%,
                rgba(115, 229, 83, 0.2) 97.56%);

    &.croc-button--blue {
      background: rgba(71, 93, 235, 0.2);
      &:hover {
        background: linear-gradient(0deg, rgba(71, 93, 235, 0.3), rgba(71, 93, 235, 0.3)), #101010;
        @apply text-white;
      }

      &:active {
        --bg: rgb(54, 54, 223);
      }
    }

    &.croc-button--red {
      background: rgba(255, 100, 90, 0.1);
      &:hover {
        background: rgba(255, 100, 90, 0.3);
        @apply text-white;
      }

      &:active {
        --bg: #FF645A;
      }
    }

    &:hover {
      background: linear-gradient(46.47deg, rgba(0, 164, 96, 0.3) 0%,
        rgba(115, 229, 83, 0.3) 97.56%);
      @apply text-white;
    }

    &:active {
      --bg: #00A460;
    }
    }
  }

  &--next {
    @apply flex justify-between pr-16;
    .croc-button__text {
      @apply flex-1 mr-4;
    }
  }

  &--prev {
    @apply flex justify-between pl-16;
    .croc-button__text {
      @apply flex-1 ml-4;
    }
  }

  &--icon {
    .croc-button__text {
      @apply flex items-center justify-center;
    }
  }

  &__append {
    @apply w-16 h-16;
  }

  &__prepend {
    @apply w-16 h-16;
  }

  &__icon {
    @apply mr-4;
  }

  &--disabled {
    --border-color: gray;
    --circles-color: gray;
    --bg: gray;
    @apply text-gray-300 bg-opacity-15 cursor-not-allowed;
    &:hover {
      @apply text-gray-300 bg-opacity-15
    }
  }
}
</style>
