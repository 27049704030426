<template>
  <div class="select-language">
    <div class="select-language__header">
      Выбери язык
    </div>
    <div class="select-language__text">
      Указанный в твоём профиле язык не поддерживается игрой
    </div>
    <div class="select-language__buttons">
      <Button v-for="l in languages"
        is-uppercase is-text-colored is-full-width
        :is-selected="selectedLanguage && selectedLanguage.id == l.id" :key="l.id"
        @click="selectedLanguage = l">
        {{ l.name }}
      </Button>
    </div>
    <div class="flex-1"></div>
    <Button
      @click="$emit('select', selectedLanguage)"
      is-full-width is-has-bg is-uppercase :disabled="!selectedLanguage">
      Подтвердить
    </Button>
  </div>
</template>

<script>
import Button from '@/components/UI/Button.vue';

export default {
  data() {
    return {
      languages: [
        {
          name: 'JavaScript',
          id: 'javascript',
        },
      ],
      selectedLanguage: null,
    };
  },
  components: {
    Button,
  },
};
</script>

<style lang="scss" scoped>
.select-language {
  @apply flex flex-col justify-between items-center h-full pb-16;
  &__header {
    @apply uppercase text-24 leading-32 font-croc-bold text-white;
  }

  &__text {
    @apply mt-8 uppercase text-12 leading-18 font-croc-bold text-center text-green;
    background: linear-gradient(45deg, #00A460, #73E553);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__buttons {
    @apply mt-32 grid grid-cols-1 gap-12;
    width: 50%;
  }
}
</style>
