<template>
  <div class="outro" >
    <canvas class="martix-rain" ref="matrix-rain"></canvas>
    <div class="outro__background" ref="outroBackground" :style="bgStyle">
    </div>
    <GameResult style="margin-top: 0px;"
    class="outro__result" ref="outroResult" @click="restart" />
    <OutroDialog ref="outroDialog" @click="goNext" />
  </div>
</template>

<script>
/* eslint-disable */
import OutroDialog from '@/components/OutroDialog.vue';
import GameResult from '@/components/GameResult.vue';
// import RandomCofee from '@/components/RandomCofee.vue';

import { scaleCanvas } from '@/utils';

const SIZE = 10;
const MATRIX_STEP = 50;
const DIALOG_TIME = 3000;
const OPACITY_DURATION = 1000;

const FADE_OUT_DURATION = 3000;
const RESULT_SCREEN_DELAY = 4000;
const RESULT_SCREEN_FADE_IN = 3000;

const RANDOM_COFEE_FADE_IN = 3000;

const TWEEN = require('tween.js');

const animate = (time) => {
  window.requestAnimationFrame(animate);
  TWEEN.update(time);
};

window.requestAnimationFrame(animate);

export default {
  data() {
    return {
      dialogShown: false,
      matrixInterval: null,
      bgState: 'STATIC',
      dialogClicked: false,
      nextClicked: false,
    };
  },
  components: {
    OutroDialog,
    GameResult,
    // RandomCofee,
  },
  computed: {
    bgStyle() {
      if (this.bgState === 'STATIC') {
        return {
          backgroundImage: "url('/images/outro-static.jpg')",
        }
      } else if (this.bgState === 'PHASE_1') {
        return {
          backgroundImage: "url('/images/outro-1.png')",
        }
      } else {
        return {
          backgroundImage: "url('/images/outro-2.png')",
        }
      }
    },
  },
  mounted() {
    let matrixElapsed = 0;
    const c = this.$refs['matrix-rain'];
    const ctx = c.getContext('2d');

    c.height = 736;
    c.width = 414;

    scaleCanvas(c, ctx, c.width, c.height);

    const letters = '日ﾊﾐﾋｰｳｼﾅﾓﾆｻﾜﾂｵﾘｱﾎﾃﾏｹﾒｴｶｷﾑﾕﾗｾﾈｽﾀﾇﾍｦｲｸｺｿﾁﾄﾉﾌﾔﾖﾙﾚﾛﾝ012345789Z:・."=*+-<>¦｜ç'.split('');
    const w = 414;
    const h = 736;
    const cols = Math.floor(w / SIZE) + 1;
    const ypos = new Array(cols).fill(0);

    const self = this;

    ctx.fillStyle = 'rgba(0, 0, 0, 0.3)';
    ctx.fillRect(0, 0, w, h);

    function matrix() {
      ctx.fillStyle = '#0001';
      ctx.fillRect(0, 0, w, h);

      ctx.fillStyle = '#0f0';
      ctx.font = '15pt Croc-Regular';

      ypos.forEach((y, ind) => {
        const text = letters[Math.floor(Math.random() * letters.length)];
        const x = ind * 20;
        ctx.fillText(text, x, y);
        if (y > 100 + Math.random() * 10000) ypos[ind] = 0;
        else ypos[ind] = y + 20;
      });

      matrixElapsed += MATRIX_STEP;

      if (matrixElapsed >= DIALOG_TIME) {
        if (!self.dialogShown) {
          self.showDialog();
        }
      }
    }

    this.matrixInterval = setInterval(matrix, MATRIX_STEP);
  },
  methods: {
    restart() {
      this.$store.dispatch('game/restartGame');
    },
    showDialog() {
      this.dialogShown = true;
      this.$refs.outroDialog.show();
    },
    goNext() {
      if (this.dialogClicked) {
        return
      };

      this.dialogClicked = true;
      const opacityTweenFrom = { opacity: 1 };
      const opacityTweenTo = { opacity: 0 };

      new TWEEN.Tween(opacityTweenFrom)
        .to({
          opacity: 0,
        }, FADE_OUT_DURATION)
        .easing(TWEEN.Easing.Quadratic.InOut)
        .onUpdate(() => {
          this.$refs.outroDialog.$el.style.opacity = `${opacityTweenFrom.opacity}`;
          this.$refs['matrix-rain'].style.opacity = `${opacityTweenFrom.opacity}`;
          this.$refs.outroBackground.style.opacity = `${1 - opacityTweenFrom.opacity}`;
        })
        .onComplete(() => {
          window.clearInterval(this.matrixInterval);
          this.$refs.outroDialog.$el.style.pointerEvents = 'none';
          this.bgState = 'PHASE_1';
        })
        .start();

      setTimeout(() => {
        this.bgState = 'PHASE_2';
        new TWEEN.Tween(opacityTweenTo)
          .to({
            opacity: 1,
          }, RESULT_SCREEN_FADE_IN)
          .easing(TWEEN.Easing.Quadratic.InOut)
          .onUpdate(() => {
            this.$refs.outroResult.$el.style.opacity = `${opacityTweenTo.opacity}`;
          })
          .delay(RESULT_SCREEN_DELAY)
          .start();
      }, FADE_OUT_DURATION + 1000)
    },
    goToRandomCofee() {
      if (this.nextClicked) {
        return
      };
      this.nextClicked = true;
      const opacityTweenFrom = { opacity: 1 };

      new TWEEN.Tween(opacityTweenFrom)
        .to({
          opacity: 0,
        }, RANDOM_COFEE_FADE_IN)
        .easing(TWEEN.Easing.Quadratic.InOut)
        .onUpdate(() => {
          this.$refs.outroResult.$el.style.opacity = `${opacityTweenFrom.opacity}`;
        })
        .onComplete(() => {
          this.$refs.outroResult.$el.style.pointerEvents = 'none';
          this.$refs.outroRandomCofee.show();
        })
        .start();
    }
  },
};
</script>

<style lang="scss" scoped>
.martix-rain {
  @apply absolute;
  left: -10px;
  width: calc(100% + 20px);
  height: 100%;
}

.outro {
  @apply h-full overflow-hidden;
  // border: 1px solid red;
  &__background {
    @apply absolute w-full h-full bg-no-repeat bg-center bg-cover opacity-0;
    @apply left-0 top-0;
    z-index: 0;
    background-size: cover;
    background-position: center center;
  }

  &__result {
    @apply absolute left-0 top-0 opacity-0 w-full;
  }

  &__randomcofee {
    @apply absolute left-0 top-0 opacity-0 w-full;
    @apply pb-12 px-16
  }
}
</style>
