<!-- eslint-disable max-len -->

<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.2222 16H6.22222C5.24038 16 4.44444 15.2041 4.44444 14.2222V10.6667H6.22222V14.2222H14.2222V1.77778H6.22222V5.33333H4.44444V1.77778C4.44444 0.795938 5.24038 0 6.22222 0H14.2222C15.2041 0 16 0.795938 16 1.77778V14.2222C16 15.2041 15.2041 16 14.2222 16ZM8 11.5556V8.88889H0V7.11111H8V4.44444L12.4444 8L8 11.5556Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconLogout',
  data() {
    return {};
  },
};
</script>
