<!-- eslint-disable max-len -->
<template>
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.375"
      y="0.375"
      width="35.25"
      height="35.25"
      fill="#00A460"
      fill-opacity="0.05"
    />
    <g clip-path="url(#clip0_12:43812)">
      <path
        d="M29.508 6.00298C24.823 5.91898 19.48 8.36798 16.098 12.167C12.866 12.228 9.71901 13.553 7.40201 15.87C7.26701 16.003 7.21901 16.202 7.27801 16.382C7.33801 16.563 7.49401 16.694 7.68201 16.721L11.536 17.273L11.06 17.806C10.883 18.004 10.892 18.305 11.08 18.493L17.507 24.92C17.604 25.017 17.732 25.066 17.861 25.066C17.98 25.066 18.099 25.024 18.194 24.939L18.727 24.463L19.279 28.317C19.306 28.505 19.454 28.643 19.633 28.703C19.679 28.718 19.727 28.725 19.776 28.725C19.918 28.725 20.063 28.663 20.163 28.564C22.448 26.279 23.773 23.132 23.834 19.9C27.637 16.511 30.106 11.17 29.997 6.49098C29.99 6.22498 29.775 6.00998 29.508 6.00298ZM24.9 14.635C24.413 15.122 23.773 15.366 23.132 15.366C22.491 15.366 21.851 15.122 21.364 14.635C20.39 13.66 20.39 12.074 21.364 11.099C22.339 10.124 23.925 10.124 24.9 11.099C25.875 12.074 25.875 13.661 24.9 14.635Z"
        fill="url(#paint0_linear_12:43812)"
      />
      <path
        d="M8.724 22.905C7.654 23.975 6.185 28.809 6.021 29.356C5.968 29.532 6.017 29.723 6.146 29.853C6.242 29.949 6.369 30 6.5 30C6.548 30 6.596 29.993 6.644 29.979C7.191 29.815 12.025 28.346 13.095 27.276C14.3 26.071 14.3 24.11 13.095 22.905C11.889 21.7 9.929 21.701 8.724 22.905Z"
        fill="url(#paint1_linear_12:43812)"
      />
    </g>
    <rect
      x="0.375"
      y="0.375"
      width="35.25"
      height="35.25"
      stroke="url(#paint2_linear_12:43812)"
      stroke-width="0.75"
    />
    <defs>
      <linearGradient
        id="paint0_linear_12:43812"
        x1="7.25317"
        y1="28.725"
        x2="29.9784"
        y2="7.11392"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00A460" />
        <stop offset="1" stop-color="#73E553" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_12:43812"
        x1="5.99994"
        y1="30"
        x2="13.9983"
        y2="22.4011"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00A460" />
        <stop offset="1" stop-color="#73E553" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_12:43812"
        x1="-4.1087"
        y1="21.75"
        x2="40.6957"
        y2="21.75"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#23E191" stop-opacity="0.9" />
        <stop offset="0.520349" stop-color="#181818" />
        <stop offset="1" stop-color="#21EB96" stop-opacity="0.9" />
        <stop offset="1" stop-color="#23E191" stop-opacity="0.9" />
      </linearGradient>
      <clipPath id="clip0_12:43812">
        <rect width="24" height="24" fill="white" transform="translate(6 6)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconHandshake',
  components: {},
  data() {
    return {};
  },
};
</script>
