<!-- eslint-disable max-len -->
<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.8">
      <rect
        x="0.5"
        y="0.5"
        width="47"
        height="47"
        rx="3.5"
        fill="#00A460"
        fill-opacity="0.05"
      />
      <g clip-path="url(#clip0_12:37447)">
        <path
          d="M35.994 36.0001V35.9991H36V27.1971C36 22.8911 35.073 19.5741 30.039 19.5741C27.619 19.5741 25.995 20.9021 25.332 22.1611H25.262V19.9761H20.489V35.9991H25.459V28.0651C25.459 25.9761 25.855 23.9561 28.442 23.9561C30.991 23.9561 31.029 26.3401 31.029 28.1991V36.0001H35.994Z"
          fill="#23E191"
        />
        <path
          d="M12.3962 19.9769H17.3722V35.9999H12.3962V19.9769Z"
          fill="#23E191"
        />
        <path
          d="M14.882 12C13.291 12 12 13.291 12 14.882C12 16.473 13.291 17.791 14.882 17.791C16.473 17.791 17.764 16.473 17.764 14.882C17.763 13.291 16.472 12 14.882 12V12Z"
          fill="#23E191"
        />
      </g>
      <rect
        x="0.5"
        y="0.5"
        width="47"
        height="47"
        rx="3.5"
        stroke="url(#paint0_linear_12:37447)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_12:37447"
        x1="-5.47826"
        y1="29"
        x2="54.2609"
        y2="29"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#23E191" stop-opacity="0.9" />
        <stop offset="0.520349" stop-color="#181818" />
        <stop offset="1" stop-color="#21EB96" stop-opacity="0.9" />
        <stop offset="1" stop-color="#23E191" stop-opacity="0.9" />
      </linearGradient>
      <clipPath id="clip0_12:37447">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(12 12)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconLinkedin',
  components: {},
  data() {
    return {};
  },
};
</script>
