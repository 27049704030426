/* eslint-disable import/prefer-default-export */
export const DOCS = {
  RULES: 'https://geecko.notion.site/cb1d22271bb74f99a2963b45fe812ed6',
};

export const SETTINGS_DOCS = [
  {
    name: 'Пользовательское соглашение',
    link: '/docs/agreement.pdf?1',
  },
  {
    name: 'Согласие на обработку ПД',
    link: '/docs/privacy_policy.pdf?1',
  },
];
