<template>
  <div class="timer" :class="{ active: isCounterActive }">{{ stringTime }}</div>
</template>

<script>
export default {
  name: 'Counter',
  props: {},
  data() {
    return {
      currentTime: 0,
      timer: null,
    };
  },
  computed: {
    stringTime() {
      const numminutes = Math.floor(
        (((this.currentTime % 31536000) % 86400) % 3600) / 60,
      )
        .toString()
        .padStart(2, '0');
      const numseconds = ((((this.currentTime % 31536000) % 86400) % 3600) % 60)
        .toString()
        .padStart(2, '0');
      return `${numminutes}:${numseconds}`;
    },
    isCounterActive() {
      return this.$store.state.game.isCounterActive;
    },
  },
  watch: {
    isCounterActive(val) {
      if (val) {
        this.startTimer();
      } else {
        this.stopTimer();
      }
    },
  },
  mounted() {
    const savedTime = localStorage.getItem('counter');
    if (savedTime) {
      this.currentTime = +savedTime;
    }
    if (this.isCounterActive) {
      this.startTimer();
    }
  },
  destroyed() {
    this.stopTimer();
  },
  methods: {
    startTimer() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          this.currentTime += 1;
          localStorage.setItem('counter', this.currentTime);
        }, 1000);
      }
    },
    stopTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },
  },
};
</script>

<style lang="scss">
.timer {
  @apply font-croc-bold text-14 leading-32 text-gray-300 text-center relative;
  &:last-child {
    &:after {
      @apply absolute leading-16 text-green;
      font-size: 8px;
      bottom: calc(100% + 2px);
      width: max-content;
      content: 'ВРЕМЯ';
      right: 0;
    }
  }
  background: linear-gradient(
      0deg,
      rgba(104, 103, 108, 0.1),
      rgba(104, 103, 108, 0.1)
    ),
    #101010;
  border-radius: 2px;
  width: 67px;
  height: 32px;
  letter-spacing: 0.1px;
  &.active {
    @apply text-gray-800;
    background: linear-gradient(46.47deg, #00a460 0%, #73e553 97.56%);
  }
}
</style>
