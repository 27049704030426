<template>
  <div class="quiz-card__scene">
    <div
      class="card"
      :class="{ 'card--flipped': isFlipped, 'card--unflip': isUnflip }"
    >
      <div class="card-face card-backing">
        <Dialog ref="answerDialog" class="quiz__answer-dialog card-dialog">
          <div ref="codeRef" class="code-bg"></div>
          <p
            v-show="number - 1 == answered"
            style="margin-top: 50px;"
            class="text-16 leading-26 font-croc-regular text-center mx-auto">
            {{ question.question }}
          </p>
        </Dialog>
      </div>
      <div class="card-face card-front">
        <Dialog class="quiz__result-dialog card-dialog" :class="[result ? 'blue' : 'red']">
          <div class="result">
            <template v-if="result">
              <div class="blue-pill top-pill"/>
              <div class="blue-pill right-pill"/>
              <div class="blue-pill bottom-left-pill"/>
              <h4 class="text-24 leading-16 mt-8 mb-20 uppercase" style="color: #00AFD6">
                Правильно
              </h4>
            </template>
            <template v-else>
              <div class="red-pill top-pill" />
              <div class="red-pill left-pill"/>
              <div class="red-pill bottom-right-pill"/>
              <h4 class="text-red text-24
              leading-16 mt-8 mb-20 uppercase" style="color: #FF645A">
                Неправильно
              </h4>
            </template>
            {{ explanation }}
          </div>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import gsap, { Power0 } from 'gsap';

import Dialog from '@/components/Dialog.vue';
// import IconSuccess from './Icons/IconSuccess.vue';
// import IconFailure from './Icons/IconFailure.vue';

export default {
  props: {
    number: Number,
    answered: Number,
    result: Boolean,
    explanation: String,
    question: {
      type: Object,
      default: () => ({
        question: '',
      }),
    },
  },
  data() {
    return {
      isFlipped: false,
      isUnflip: false,
    };
  },
  components: {
    Dialog,
  },
  methods: {
    flip() {
      this.isFlipped = true;
    },
    unflip() {
      this.isUnflip = true;
      setTimeout(() => {
        this.isFlipped = false;
        this.isUnflip = false;
      }, 500);
    },
    codeDisappear() {
      console.log('[quizCard] code disappear');
      const tl = gsap.timeline();
      tl.fromTo(this.$refs.codeRef, {
        duration: 2,
        // background: 'none',
        backgroundPositionY: '-140px',
      },
      {
        backgroundPositionY: '500px',
      });
      tl.to(this.$refs.codeRef, {
        opacity: 0,
        duration: 1,
      });
    },
  },
  computed: {
    cardStyle() {
      return {
        top: `${Math.abs(5 - this.number) * 5}px`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.code-bg {
  background: linear-gradient(
      90.55deg,
      rgba(255, 100, 90, 0) 0.43%,
      rgba(0, 164, 96, 0.05) 39.67%
    ),
    #101010;
  // background-color: black;
  background-image: url('/images/cardBack.svg');
  z-index: 10;
  left: 1px;
  top: 1px;
  border-radius: 10px;
  // overflow: hidden;
  background-size: 500px;
  background-repeat: no-repeat;
  background-position: bottom;
  background-position-y: -530px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  position: absolute;
}
.result {
  @apply text-16 leading-26 font-croc-regular text-center mx-auto;
  padding-top: 50px;
  & svg {
    @apply mx-auto;
  }
}
.quiz-card {
  &__scene {
    width: 100%;
    perspective: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.card-front {
  background-size: contain;
  // border: 1px solid red;
}
.card-backing {
  background-image: url('/images/cardBack.svg');
  // background-color: black;
  // border: 1px solid red;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  transform: rotateY(180deg);
}
.card-face {
  position: absolute;
  // border: 1px solid red;
  backface-visibility: hidden;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  z-index: 1;

  &.card-backing {
    z-index: 100;
  }

  &.card-front {
    z-index: 4;
  }
}
.red-pill {
  background: url('/images/red_pill.png');
  @apply absolute bg-center bg-no-repeat bg-contain;
  width: 50px;
  height: 50px;
}
.blue-pill {
  background: url('/images/blue_pill.png');
  @apply absolute bg-center bg-no-repeat bg-contain;
  width: 50px;
  height: 50px;
}
.top-pill {
  top: 20px;
  left: calc(50% - 25px);
  transform: rotate(-70deg);
}
.bottom-left-pill {
  bottom: -20px;
  left: 20px;
}
.bottom-right-pill {
  bottom: -20px;
  right: 20px;
}
.left-pill {
  bottom: 50px;
  left: 5px;
  transform: rotate(80deg);
}
.right-pill {
  bottom: 50px;
  right: 5px;
  transform: rotate(80deg);
}

.card-dialog {
  width: 100%;
  min-height: 288px;
}

.card {
  height: 100%;
  width: 100%;
  position: relative;
  transform-style: preserve-3d;
  transform: rotateY(180deg);
  &.card--flipped {
    animation: cardFlip .2s forwards linear;
    transform: rotateZ(0deg) rotateY(0deg);
  }
  &.card--unflip {
    transform: rotateZ(0deg) rotateY(180deg);
    animation: cardUnFlip .2s forwards linear;
  }
}

@keyframes cardAppear {
  0% {
    transform: rotate3d(0,0,1,15deg) translate3d(0,-100vh,0);
  }
  30% {
    transform: rotate3d(0,0,0,0deg) translate3d(0,0,0);
  }
  65% {
    transform: rotate3d(0,0,0,0deg) translate3d(0,0,0) scale3d(1,1,1);
  }
  80% {
    transform: rotate3d(0,0,1,-5deg) scale3d(1.05,1.05,1);
  }
}

@keyframes cardFlip {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes cardUnFlip {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

</style>
