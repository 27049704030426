<template>
  <div class="quiz">
    <template v-if="showBetweenLevels">
      <div class="quiz__dialogs">
        <Card ref="betweenCard">
          <Dialog class="without-border">
            <div class="conversation-content">
              <div ref="level" class="level">
                <div class="conversation-content__subtitle"
                  style="color: white; font-size: 24px; font-weight: bold"
                >
                  УРОВЕНЬ
                </div>
                <div class="flex w-full justify-center">
                  <div class="quiz__letter-container">
                    <div class="quiz__letter-border">
                      <div class="quiz__letter">
                        {{ currentStage + 1 }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div ref="levelContext">
                <div  class="conversation-content__title"
                  style="font-size: 24px; font-weight: bold">Приготовься</div>
                <div class="conversation-content__text">
                  {{ continueText }}
                </div>
              </div>
            </div>
          </Dialog>
        </Card>
      </div>
      <Button
        class="next-lvl-btn"
        ref="readyButton"
        is-next
        is-uppercase
        @click="goToNextStage"
        >Я готов</Button
      >
    </template>
    <template v-else-if="showResult">
      <div class="quiz__dialogs">
        <Card ref="resultCard">
          <Dialog :class="`${'without-border'}`">
            <div class="result text-center mx-auto">
              <div class="result__container">
                <div class="result__right">
                  <img src="/images/blue_pill.png" />
                  <div class="result__right-text">
                    {{ rightAnswers }}
                  </div>
                </div>
                <div class="result__wrong">
                  <img src="/images/red_pill.png" />
                  <div class="result__wrong-text">
                    {{ wrongAnswers }}
                  </div>
                </div>
              </div>
              <h3 class="text-24 leading-28 text-bold uppercase" >Уровень пройден</h3>
              <p class="mt-16 text-16 leading-26 font-croc-regular">
                {{ resultText }}
              </p>
            </div>
          </Dialog>
        </Card>
      </div>
      <Button
        class="next-lvl-btn"
        ref="nextLevelBtn"
        is-next
        is-uppercase
        @click="goToLevelBetween"
        >К следующему уровню</Button
      >
    </template>
    <template v-else-if="showOpenedCode" >
      <div class="quiz__dialogs" style="padding-top: 100px">
        <div class="quiz__letter-outer" ref="codeLetter">
          <div v-if="showLetter" class="quiz__letter-container">
            <div class="quiz__letter-border">
              <div class="quiz__letter">
                {{ codeLetter || '' }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="quiz__code-info"
          :class="{ 'quiz__code-info--empty': !showLetter }"
        >
          <div class="quiz__code-title">Ты открыл часть кода</div>
          <div class="quiz__code-letters">
            <div class="quiz__code-letters-container">
              <template v-for="(letter, i) in code">
                <CodeLetter v-if="letter" :key="i">{{ letter }}</CodeLetter>
                <CodeLetter v-else :key="`hidden-${i}`" />
              </template>
            </div>
          </div>
        </div>
      </div>
      <Button is-uppercase @click="goFromCode">Далее</Button>
    </template>
    <template v-else>
      <div class="quiz__dialogs">
        <QuizCard
          v-for="(n, i) in maxQuestions"
          :key="i"
          ref="quizCards"
          :number="5 - i"
          :result="result"
          :question="question"
          :explanation="explanation"
          :answered="answeredQuestions"
        />
      </div>
      <div class="quiz__buttons">
        <Button
          v-if="showNextButton && result"
          ref="nextButton"
          is-next
          is-has-bg
          is-blue
          is-full-width
          is-text-colored
          is-uppercase
          @click="getNextQuestion"
          :disabled="loading"
          >Далее</Button
        >
        <Button
          v-else-if="showNextButton && !result"
          ref="nextButton"
          is-next
          is-has-bg
          is-red
          is-full-width
          is-text-colored
          is-uppercase
          @click="getNextQuestion"
          :disabled="loading"
          >Далее</Button
        >
        <div
          v-else
          class="quiz-answer-buttons flex w-full justify-between"
          ref="quizButtons"
        >
          <Button
            is-prev
            is-text-colored
            is-red
            is-uppercase
            isHalfWidth
            @click="answer(false)"
            :disabled="loading"
            >не верно</Button
          >
          <Button
            is-next
            is-text-colored
            is-uppercase
            isHalfWidth
            @click="answer(true)"
            :disabled="loading"
            >верно</Button
          >
          <p class="w-full absolute text-white text-12 text-center"
            style="bottom: 10px; opacity: 0.7">
            Пройди игру до конца и лучшие результаты <br />будут на турнирной таблице!
          </p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
/* eslint-disable */
import gsap, { Bounce, Power0 } from 'gsap';
import check from '@/assets/images/check.svg';
import cross from '@/assets/images/cross.svg';

import Button from '@/components/UI/Button.vue';
import CodeLetter from '@/components/UI/CodeLetter.vue';
import Dialog from './Dialog.vue';
import IconSuccess from './Icons/IconSuccess.vue';
import IconFailure from './Icons/IconFailure.vue';

import QuizCard from './QuizCard.vue';

import Card from './Card.vue';

const TWEEN = require('tween.js');

const animate = (time) => {
  window.requestAnimationFrame(animate);
  TWEEN.update(time);
};

window.requestAnimationFrame(animate);

const SWIPE_ANIM_DURATION = 1500;
const SWIPE_ANIM_ANGLE_DELTA = 30;
const SWIPE_ANIM_X_DELTA =
  window.innerWidth > 576 ? 616 : window.innerWidth + 40;
const SWIPE_ANIM_Y_DELTA = 100;

export default {
  name: 'Quiz',
  check,
  cross,
  components: {
    Button,
    Dialog,
    CodeLetter,
    IconSuccess,
    IconFailure,
    QuizCard,
    Card,
  },
  data() {
    return {
      result: null,
      negativeSelected: false,
      positiveSelected: false,
      showResult: false,
      loading: false,
      showOpenedCode: false,
      explanation: null,
      showNextButton: false,
      maxQuestions: 0,
      isInit: true,
      showLetter: false,
      showBetweenLevels: false,
    };
  },
  computed: {
    hasResult() {
      return typeof this.result === 'boolean';
    },
    resultDialogStyle() {
      return {};
    },
    question() {
      return this.$store.state.game?.question || {};
    },
    gameInfo() {
      return this.$store.state.game.gameInfo;
    },
    currentStage() {
      return this.$store.state.game.currentStage;
    },
    answeredQuestions() {
      return this.gameInfo?.stages.find(
        (item) => item.stage === this.currentStage,
      )?.answered_questions;
    },
    rightAnswers() {
      return (
        this.gameInfo?.stages.find((item) => item.stage === this.currentStage)
          .correct_answers || 0
      );
    },
    wrongAnswers() {
      return 5 - this.rightAnswers;
    },
    continueText() {
      switch (this.currentStage) {
        case 1:
          return 'Первое испытание уже не за горами, а это значит, что пора отправиться навстречу приключениям. И с этим может отлично помочь Croc Code, ведь тут всегда есть интересные и захватывающие проекты, за которые может взяться каждый желающий.';
        case 2:
          return 'У тебя уже есть наработанное портфолио, или же наоборот путь в IT сфере только начинается? Присоединяйся к Croc Code, тут много интересных задач! Вперёд?';
        case 3:
          return 'Отлично работаешь. Уверены, в слаженном коллективе ты покажешь гораздо более высокие результаты. Такая команда, например, есть в Croc Code. Уже готов заслать им твоё CV. Если ты, конечно, вернёшься из Матрицы живым. Запускаю!';
        case 4:
          return 'Ты уже близок к тому, чтобы освободить тысячи людей из под гнета машин. Это по-настоящему изменит жизни людей, а, значит, и твою собственную! И для того, чтобы действительно влиять на окружение - присоединяйся к Croc Code. Здесь мы меняем мир к лучшему! ';
        default:
          return '';
      }
    },
    resultText() {
      // TODO нужны тексты
      if (this.rightAnswers < 2) {
        switch (this.currentStage) {
          case 1:
            return 'А ты хорош! Признаюсь, не ожидал такого. Похоже, именно ты тот герой из пророчества.';
          case 2:
            return 'Неплохо-неплохо, такие спецы всегда нужны в нашем небольшом поселении. Разминай пальцы - впереди много работы.';
          case 3:
            return 'Сойдет, но с такими навыками героем ты еще не скоро станешь. Предлагаю попробовать себя в, например, уборке помещений.';
          case 4:
            return 'Полезай обратно в капсулу. Так ты, кхм, будешь хоть как-то полезен.';
          default:
            return '';
        }
      }
      if (this.rightAnswers <= 5) {
        switch (this.currentStage) {
          case 1:
            return 'Ого, кодовая материя трещит под твоим натиском.';
          case 2:
            return 'Хм-хм, как ты это делаешь? Только не говори, что научился этому на новомодных курсах из реального мира?';
          case 3:
            return 'Мне нравится твой подход, давай, прорви брешь в системе! ';
          case 4:
            return 'Отлично прошел финальный этап. Теперь осталось лишь разгадать шифр.';
          default:
            return '';
        }
      }
      return 'Неплохо, но какой-то ты одновекторный. А вот поработал бы в Croc Code — смог бы как прокачиваться в различных скилл-билдах, так и мигрировать между высококлассными и интересными проектами.';
    },
    levelText() {
      switch (this.currentStage) {
        case 1:
          return 'ВТОРОЙ';
        case 2:
          return 'ТРЕТИЙ';
        case 3:
          return 'ЧЕТВЕРТЫЙ';
        default:
          return '';
      }
    },
    code() {
      return this.$store.getters['game/code'];
    },
    codeLetter() {
      return this.$store.getters['game/codeLetter'];
    },
  },
  async mounted() {
    await this.$store.dispatch('game/fetchGame', true)
    console.log('[QUIZ]', this.answeredQuestions, this.currentStage, this.gameInfo)
    if (this.answeredQuestions < 5) {
      await this.$store.dispatch('game/fetchQuestion');
      this.maxQuestions = 5 - this.answeredQuestions;
      this.$nextTick(() => {
        this.showDeck();
        gsap.fromTo(
          this.$refs.quizButtons,
          { autoAlpha: 0 },
          {
            duration: this.maxQuestions * 0.25,
            autoAlpha: 1,
          },
        );
        this.$store.dispatch('game/activateCounter');
      });
    } else {
      this.resultAppear();
    }
  },
  watch: {
    async currentStage() {
      await this.$store.dispatch('game/fetchQuestion');
    },
  },
  methods: {
    goToLevelBetween() {
      if (this.currentStage < 4) {
        // gsap.to(this.$refs.nextLevelBtn.$el, {
        //   duration: 1,
        //   autoAlpha: 0,
        // });
        // this.$refs.resultCard.disappear(() => {
          this.showResult = false;
          this.showBetweenLevels = true;
          this.$nextTick(() => {
            let tl = gsap.timeline()
            tl.from(this.$refs.level, {
              duration: 1,
              autoAlpha: 0,
            })
            tl.to(this.$refs.level, {
              duration: 0.5,
              autoAlpha: 0,
            })
            tl.from(this.$refs.levelContext, {
              autoAlpha: 0,
              duration: 1,
            })
          })
        //   this.$nextTick(() => {
        //     this.$refs.betweenCard.appear(() => {
        //       setTimeout(() => {
        //         this.$refs.betweenCard.flip();
        //       }, 250);
              // gsap.to(this.$refs.readyButton.$el, {
              //   duration: 1,
              //   autoAlpha: 1,
              // });
        //     });
        //   });
        // });
      } else {
        this.$store.commit('game/SET_SHOW_FINAL', true);
      }
    },
    async goToNextStage() {
      this.$store.dispatch('game/clearQuestion');
      await this.$store.dispatch('game/fetchQuestion', this.currentStage + 1);
      this.maxQuestions = 5;
      gsap.to(this.$refs.readyButton.$el, {
        duration: 1,
        autoAlpha: 0,
      });
      this.$refs.betweenCard.disappear(() => {
        this.showBetweenLevels = false;
        this.result = null;
        this.$nextTick(() => {
          this.showDeck();
          gsap.fromTo(
            this.$refs.quizButtons,
            { autoAlpha: 0 },
            {
              duration: this.maxQuestions * 0.25,
              autoAlpha: 1,
            },
          );
        });
        this.$store.dispatch('game/upStage');
        this.$store.dispatch('game/activateCounter');
      });
    },
    moveDeck() {
      this.$refs.quizCards.forEach((c, i, array) => {
        gsap.to(c.$el, {
          duration: 1,
          x: 0,
          onComplete: () => {
            const card = this.$refs.quizCards[this.$refs.quizCards.length - 1]
            card.codeDisappear()
            gsap.to(this.$refs.quizButtons, {
              autoAlpha: 1,
              display: 'inherit',
            })
          }
        })
      })
    },
    showDeck() {
      let itemProcessed = 0
      gsap.to(this.$refs.quizButtons, {
        duration: 0,
        display: 'none',
      })
      this.$refs.quizCards.forEach((c, i, array) => {
        console.log('item', itemProcessed)
        let tl = gsap.timeline(
          {
            delay: 0.25 * i,
            onComplete: () => {
              if (itemProcessed === array.length) {
                this.moveDeck()
                
              }
            }
          })
        tl.set(c.$el, {
          scale: 0.6,
        })
        tl.fromTo(c.$el, {
          duration: 1,
          x: -500,
          ease: Power0.easeNone
        },
        {
          duration: 1,
          x: 500,
          ease: Power0.easeNone
        });
        tl.to(c.$el, {
          duration: 0,
          scale: 1,
          onComplete: () => {
            itemProcessed++
          }
        })
      });
    },
    hideQuizButtons() {
      gsap.to(this.$refs.quizButtons, {
        duration: 0.5,
        autoAlpha: 0,
        onComplete: () => {
          this.showNextButton = true;
          this.$nextTick(() => {
            gsap.fromTo(
              this.$refs.nextButton.$el,
              { autoAlpha: 0 },
              {
                duration: 0.5,
                autoAlpha: 1,
              },
            );
          });
        },
      });
    },
    hideNextButton(cb) {
      gsap.to(this.$refs.nextButton.$el, {
        duration: 0.6,
        autoAlpha: 0,
        onComplete: () => {
          cb();
        },
      });
    },
    moveCard(res) {
      const card = this.$refs.quizCards.pop();
      gsap.to(card.$el, {
        duration: 0.5,
        opacity: 0,
      });
    },
    resultAppear() {
      this.showResult = true;
      this.$nextTick(() => {
        this.$refs.resultCard.appear(() => {
          setTimeout(() => {
            this.$refs.resultCard.flip();
          }, 250);
          gsap.fromTo(
            this.$refs.nextLevelBtn.$el,
            { autoAlpha: 0 },
            {
              duration: 0.5,
              autoAlpha: 1,
            },
          );
        });
      });
    },
    goFromCode() {
      this.showOpenedCode = false;
      this.showLetter = false;
      this.resultAppear();
    },
    async answer(val) {
      this.$store.dispatch('game/pauseCounter');
      this.loading = true;
      const res = await this.$http
        .post(`/game/answer-text-question/${this.question.id}`, {
          answer: val,
        })
        .finally(() => {
          this.loading = false;
        });
      await this.$store.dispatch('game/fetchGame');
      if (res.data) {
        this.result = res.data.is_correct;
        this.explanation = res.data.explanation;
        const card = this.$refs.quizCards[this.$refs.quizCards.length - 1];
        card.flip();
        this.hideQuizButtons();
      }
    },
    async getNextQuestion() {
      if (this.answeredQuestions < 5) {
        this.loading = true;
        await this.$store.dispatch('game/fetchQuestion').finally(() => {
          this.loading = false;
        });
        this.moveCard(this.result);
        this.hideNextButton(() => {
          this.result = null;
          this.showNextButton = false;
          this.$store.dispatch('game/activateCounter');
          const card = this.$refs.quizCards[this.$refs.quizCards.length - 1];
          card.codeDisappear()
          this.$nextTick(() => {
            gsap.fromTo(
              this.$refs.quizButtons,
              { autoAlpha: 0 },
              {
                autoAlpha: 1,
                duration: 0.6,
              },
            );
          });
        });
      } else {
        this.$nextTick(() => {
          this.moveCard(this.result);
          this.hideNextButton(() => {
            this.result = null;
            this.showNextButton = false;
            this.$nextTick(() => {
              if (this.codeLetter) {
                this.showOpenedCode = true;
                this.$nextTick(() => {
                  setTimeout(() => {
                    this.showLetter = true;
                    this.$nextTick(() => {
                      gsap.from(this.$refs.codeLetter, {
                        duration: 0.75,
                        scale: 2,
                        ease: Bounce.easeOut,
                      });
                    });
                  }, 500);
                });
              } else {
                this.resultAppear();
              }
            });
          });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.level {
  @apply absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.quiz {
  @apply relative z-0 flex flex-col justify-between pb-24 overflow-hidden;
  // border: 1px solid red;
  height: 100%;
  &__top-img {
    @apply absolute;
    width: 100%;
    top: -8px;
    left: 0;
  }
  &__buttons {
    @apply flex justify-between;
  }

  &__result-dialog {
    @apply absolute w-full;
    z-index: -2;
  }

  &__answer-dialog {
    @apply absolute w-full top-4;
    min-height: 310px;
  }

  &__dialogs {
    @apply relative;
  }

  &__negative {
    @apply absolute bg-red h-320 w-20 z-10;
    left: -30px;
    opacity: 0;
    filter: blur(15px);
  }

  &__positive {
    @apply absolute bg-green h-320 w-20 z-10;
    right: -30px;
    opacity: 0;
    filter: blur(15px);
  }

  &__letter-outer {
    @apply flex items-center justify-center;
  }

  &__letter-container {
    @apply w-120 h-120 relative flex items-center justify-center;
    border-radius: 24px;
    padding: 16px;
    background: linear-gradient(
        90.55deg,
        rgba(255, 100, 90, 0) 0.43%,
        rgba(0, 164, 96, 0.05) 39.67%
      ),
      #101010;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 24px;
      padding: 16px;
      background: linear-gradient(46.47deg, #00a460 0%, #73e553 97.56%);
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }
    &:after {
      content: '';
      content: '';
      width: 250%;
      position: absolute;
      height: 250%;
      z-index: 1;
      background-size: contain;
      // background-image: url("data:image/svg+xml,%3Csvg width='280' height='289' viewBox='0 0 280 289' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_f)'%3E%3Crect x='84' y='84' width='111.482' height='120.805' rx='9.32256' fill='url(%23paint0_linear)'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_f' x='0' y='0' width='279.482' height='288.805' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='42' result='effect1_foregroundBlur'/%3E%3C/filter%3E%3ClinearGradient id='paint0_linear' x1='84' y1='204.805' x2='203.924' y2='99.6691' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2300A460'/%3E%3Cstop offset='1' stop-color='%2373E553'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
    }
  }

  &__letter-border {
    @apply w-full h-full flex items-center justify-center;
    position: relative;
    z-index: 9;
    background: linear-gradient(
        0deg,
        rgba(0, 164, 96, 0.15),
        rgba(0, 164, 96, 0.15)
      ),
      #101010;
    border: 4px solid #00a460;
  }

  &__letter {
    @apply uppercase font-croc-bold text-green;
    font-size: 55px;
    line-height: 81px;
  }

  &__code-info {
    @apply mt-48;
    &--empty {
      @apply mt-168;
    }
  }

  &__code-title {
    @apply text-18 leading-28 text-white font-croc-bold uppercase text-center;
  }

  &__code-letters {
    @apply mt-8 flex justify-center;
  }

  &__code-letters-container {
    @apply grid grid-cols-4 gap-8;
  }
}
.result {
  @apply text-16 leading-26 font-croc-regular text-center mx-auto;
  & svg {
    @apply mx-auto;
  }
  h3 {
    font-weight: 800;
    margin-top: 30px;
  }

  &__container {
    @apply flex items-center justify-center mt-8;
    font-weight: 600;
    
  }

  &__right {
    @apply flex;
  }

  &__right-text {
    @apply text-24 leading-28 ml-4;
    font-weight: 800;
    color: #00AFD6
  }

  &__wrong {
    @apply flex ml-16;
  }

  &__wrong-text {
    @apply text-24 leading-28 ml-4;
    color: #FF645A;
    font-weight: 800;
  }
}

.card-1 {
  @apply top-4;
}

.card-2 {
  @apply top-8;
}

.card-3 {
  @apply top-12;
}

.card-4 {
  @apply top-16;
}

.card-5 {
  @apply top-20;
}

.quiz-answer-buttons {
  opacity: 0;
  padding-bottom: 30px;
}

.next-lvl-btn {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>
