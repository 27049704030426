<template>
  <div class="game-header">
    <IconLogo />
    <button v-if="isAuthenticated" @click="openSettings" class="menu">Меню</button>
    <router-link v-else :to="{ name: 'LandingPage' }" class="menu">На главную</router-link>
  </div>
</template>

<script>
import IconLogo from '@/components/Icons/IconLogo.vue';

export default {
  name: 'GameHeader',
  components: {
    IconLogo,
  },
  methods: {
    openSettings() {
      this.$modal.show('settings-modal');
    },
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters['user/isAuthenticated'];
    },
  },
};
</script>

<style lang="scss" scoped>
.game-header {
  @apply absolute top-0 left-0 flex items-center justify-between w-full;
  z-index: 9;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
  // background-color: #0f1012;

  .menu {
    --border-color: #03b36a;
    --bg: rgba(0, 164, 96, 0.15);
    @apply font-croc-bold uppercase;
    font-size: 12px;
    padding: 4px 14px;
    border-image-slice: 1;
    color: #23E191;
    background: var(--bg);
    border-width: 2px;
    border-style: solid;
    border-image-source: linear-gradient(
      to left,
      var(--border-color),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      var(--border-color)
    );
  }
}
</style>
