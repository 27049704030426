<template>
  <div class="game-layout"
    :style="{paddingTop: '150px'}"
    :class="{'game-layout--no-bg': hideBg }">
    <template v-if="gameInfo && gameInfo.id && quizComplete">
      <outro :style="{marginTop: '-150px'}" v-if="gameInfo.is_finished" />
      <template v-else>
        <hud-panel></hud-panel>
        <code-enter />
      </template>
    </template>
    <template v-else>
      <hud-panel></hud-panel>
      <quiz v-if="gameInfo && gameInfo.id && (!quizComplete || (quizComplete && !showFinal))" />
      <conversation v-else :content="dialogContent" :endOfDialog="endOfDialog" />
    </template>
    <!-- <SelectLanguage v-else @select="selectLanguage"/> -->
  </div>
</template>

<script>
/* eslint-disable */
import HudPanel from '@/components/HudPanel.vue';
import Conversation from './Conversation.vue';
import CodeEnter from './CodeEnter.vue';
import Quiz from './Quiz.vue';
import Outro from './Outro.vue';
import SelectLanguage from './SelectLanguage.vue';
import Intro from './Intro.vue';

export default {
  name: 'Game',
  components: {
    HudPanel,
    Conversation,
    CodeEnter,
    Quiz,
    SelectLanguage,
    Outro,
    Intro,
  },
  data() {
    return {
      interval: 30,
      dialogContent: {
        1: {
          text:
            'Эй-эй! Ты что здесь делаешь? Мы же, не может быть... Неужели из одного слоя лжи мы угодили в другой! Сам посуди, у тебя же Nokia 3310 в 2к21, явный сбой системы. Ну, пошли.',
          answers: [
            { text: 'Куда, ты вообще кто?', next: 2 },
            { text: 'Погнали, разнесем тут все.', next: 3 },
          ],
        },
        2: {
          text:
            'Ах, черт, они снова поработали с твоей памятью. В общем, мир вокруг тебя - иллюзия. Здесь не настоящие все, даже я. Мы подключены к виртуальной симуляции.',
          answers: [{ text: 'Парень, ты чего?', next: 4 }],
        },
        3: {
          text:
            'Нет времени на размусоливания, давай я кратко опишу ситуацию. Короче, теперь машины - главные на этой земле, а люди на ней используется в качестве расходного материала, словно батарейки. В вашем мире скоро даже выйдет кино про это, как его там, еще с постаревшим Киану Ривзом.',
          answers: [{ text: 'Парень, ты чего?', next: 4 }],
        },
        4: {
          text:
            'А ты что думал? Считаешь, по какой причине мы тут живем как в гребаной антиутопии? Ах да, это она и есть.',
          answers: [
            { text: 'Я за спасибо не работаю', next: 5 },
            { text: 'Пора спасти этот мир!', next: 6 },
          ],
        },
        5: {
          text:
            'Я тебя понял. Пожалуйста, сдай свое снаряжение и приляг вон в ту капсулу. Что, не хочется? Ну вот и славно. ',
          answers: [{ text: 'Начать', next: 7 }],
        },
        6: {
          text: 'Вот это настрой героя! За работу!',
          answers: [{ text: 'Начать', next: 'last' }],
        },
        7: {
          text:
            'Чтобы пробудиться и выбраться из матрицы нужен код доступа. За прохождение каждого уровня будет открываться одна буква',
          image: 'code-preview.svg',
          answers: [{ text: 'Понятно', next: 'last' }],
        },

        last: {
          subtitle: 'УРОВЕНЬ ПЕРВЫЙ',
          title: 'Приготовься',
          text:
            'Тебя ждёт пять вопросов на время, в которых тебе нужно выбрать, верно утверждение или нет',
          answers: [{ text: 'Я готов', next: 'end' }],
        },
      },
    };
  },
  methods: {
    endOfDialog() {
      this.$store.dispatch('game/startGame').then(this.$store.dispatch('game/fetchQuestion'));
    },
    selectLanguage(lang) {
      this.$store.dispatch('user/updateLanguage', {
        language: lang.id,
      });
    },
  },
  computed: {
    gameInfo() {
      return this.$store.state.game.gameInfo;
    },
    showFinal() {
      return this.$store.state.game.showFinal;
    },
    quizComplete() {
      return this.gameInfo?.stages.every(
        (item) => item.answered_questions === 5,
      );
    },
    needChangeLanguage() {
      return this.$store.getters['user/needChangeLanguage'];
    },
    hideBg() {
      return (!this.needChangeLanguage
        && this.gameInfo
        && this.gameInfo.id
        && this.quizComplete
        && this.gameInfo.is_finished && this.showFinal);
    },
  },
  watch: {},
  async mounted() {
    console.log(this.$store.state);
    if (this.quizComplete) {
      this.$store.dispatch('game/setCurrentStage', 4);
      this.$store.commit('game/SET_SHOW_FINAL', true);
    }
    // разкомментить для рестарта
    // await this.$store.dispatch('game/restartGame');
  },
};
</script>

<style lang="scss" scoped>
.game-layout {
  @apply bg-top relative;
  height: 100%;
  padding-top: 100px;
  background-repeat: repeat no-repeat, no-repeat;
  background-size: contain;
  background-image: linear-gradient(
      180deg,
      #101010 0%,
      rgba(16, 16, 16, 0) 100%
    ),
    url('/images/game-matrix.png');

  &--no-bg {
    background: none;
    padding-top: 0;
  }
}
@media screen and (max-width: 320px) {
}
</style>
