<template>
  <div class="social-container">
    <a
      :class="`social-item social-item--size-${size}`"
      v-for="social in socialItems"
      :key="social.id"
      :href="social.link"
      target="_blank"
    >
      <component :is="`icon-${social.id}`"></component>
    </a>
  </div>
</template>

<script>
import IconVk from './Icons/IconVk.vue';
import IconSite from './Icons/IconSite.vue';
import IconGit from './Icons/IconGit.vue';
import IconFb from './Icons/IconFb.vue';
import IconLinkedin from './Icons/IconLinkedin.vue';
import IconTg from './Icons/IconTg.vue';
import IconInstagram from './Icons/IconInstagram.vue';
import IconHabr from './Icons/IconHabr.vue';
import IconYoutube from './Icons/IconYoutube.vue';

export default {
  name: 'CrocSocialNetworkLinks',
  components: {
    IconVk,
    IconSite,
    IconGit,
    IconFb,
    IconLinkedin,
    IconTg,
    IconInstagram,
    IconHabr,
    IconYoutube,
  },
  data() {
    return {
      socialItems: [
        { id: 'site', link: ' https://croccode.ru/career ' },
        { id: 'habr', link: 'https://career.habr.com/companies/croccode' },
        { id: 'git', link: ' https://github.com/croc-code ' },
        { id: 'fb', link: 'https://www.facebook.com/crocode.ru' },
        // { id: 'vk', link: 'https://vk.com/crocincor' },
        // { id: 'linkedin', link: 'https://www.linkedin.com/company/croc/' },
        // { id: 'tg', link: 'https://t.me/crocitnow' },
        // { id: 'youtube', link: 'https://www.youtube.com/user/Crocincor' },
        { id: 'instagram', link: 'https://instagram.com/croc_code' },
      ],
    };
  },
  props: {
    size: { type: String, default: 'md' },
  },
};
</script>

<style lang="scss">
.social {
  &-container {
    @apply flex flex-wrap w-auto;
  }
  &-item {
    @apply flex items-center;
    box-sizing: border-box;
    height: var(--size);
    min-width: var(--size);
    width: max-content;
    margin-bottom: 10px;
    &--size {
      &-md {
        @apply rounded-4;
        --size: 48px;
        padding-right: 14px;
      }
      &-sm {
        @apply m-4 px-4 rounded-2;
        --size: 32px;
        background: rgba(0, 164, 96, 0.25);
        & svg {
          height: 16px;
        }
      }
    }
  }
}
</style>
