<!-- eslint-disable max-len -->
<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M46.9171 7.55566C46.6044 7.23556 46.2324 6.98148 45.8225 6.80809C45.4126 6.63471 44.973 6.54544 44.5289 6.54544C44.0849 6.54544 43.6452 6.63471 43.2353 6.80809C42.8254 6.98148 42.4534 7.23556 42.1407 7.55566L17.0813 33.0333L6.55294 22.3094C6.22827 21.991 5.845 21.7406 5.42503 21.5726C5.00506 21.4045 4.5566 21.3221 4.10526 21.3301C3.65392 21.338 3.20853 21.4361 2.79454 21.6188C2.38054 21.8015 2.00604 22.0652 1.69241 22.3948C1.37879 22.7245 1.13218 23.1136 0.966674 23.54C0.801168 23.9664 0.720001 24.4218 0.727809 24.88C0.735618 25.3383 0.832249 25.7905 1.01218 26.2108C1.19212 26.6312 1.45184 27.0114 1.7765 27.3298L14.6931 40.4443C15.0057 40.7644 15.3778 41.0185 15.7877 41.1919C16.1976 41.3653 16.6372 41.4545 17.0813 41.4545C17.5253 41.4545 17.965 41.3653 18.3749 41.1919C18.7848 41.0185 19.1568 40.7644 19.4695 40.4443L46.9171 12.576C47.2586 12.2562 47.5311 11.8681 47.7174 11.4361C47.9038 11.004 48 10.5375 48 10.0659C48 9.5942 47.9038 9.12767 47.7174 8.69564C47.5311 8.26362 47.2586 7.87547 46.9171 7.55566Z"
        fill="url(#paint222_linear)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint222_linear"
        x1="0.727295"
        y1="41.4545"
        x2="34.6019"
        y2="-2.1238"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00A460" />
        <stop offset="1" stop-color="#73E553" />
      </linearGradient>
      <clipPath id="clip0">
        <rect width="48" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconSuccess',
  components: {},
  data() {
    return {};
  },
};
</script>
