<template>
  <div class="lg:flex flex-row justify-between mobius">
    <div class="flex flex-col w-full">
      <div class="  flex flex-row space-x-20 w-full">
        <div class="flex flex-col space-y-20">
          <div class="speaker" style="margin-bottom: 120px;">
            <img class="speaker__photo" :src="`/images/speaker-1.png`" />
            <div class="my-4">Екатерина Герт</div>
            <!-- <div>{{ speaker.position }}</div> -->
          </div>
        </div>
        <div class="flex flex-col w-full border-bottom">
          <div class="landing-mobius-header">
            19 ноября, 12:45 - 13:25
          </div>
          <h2 class="landing-mobius-h2">
            Адаптация новичков<br /> через наставничество
          </h2>
        </div>
      </div>
      <div class="flex flex-row space-x-20 w-full pt-20">
        <div class="flex flex-col space-y-20">
          <div class="speaker" v-for="(speaker, idx) in speakers" :key="idx">
            <img class="speaker__photo" :src="`/images/${speaker.photo}`" />
            <div class="my-4">{{ speaker.name }}</div>
            <!-- <div>{{ speaker.position }}</div> -->
          </div>
        </div>
        <div class="flex flex-col w-full">
          <div class="landing-mobius-header">
            19 ноября  15:30-17:20
          </div>
          <h2 class="landing-mobius-h2">
            Бизнес-игра «11 ошибок<br />аналитиков при сборе<br />требований»
          </h2>
        </div>
      </div>
    </div>
    <div class="my-border"></div>
    <div
    class=" mt-20 lg:mt-0 flex flex-row w-full space-x-20">
      <div class="flex flex-col space-y-20">
        <!-- <div class="mb-16 text-green text-18 leading-24">Спикеры:</div> -->
        <div class="speaker">
          <img class="speaker__photo" :src="`/images/speaker-1.png`" />
          <div class="my-4">Евгений Подклетнев</div>
          <!-- <div>{{ speaker.position }}</div> -->
        </div>
      </div>
      <div class="flex flex-col">
        <div class="landing-mobius-header">
          20 ноября  13:05-13:25
        </div>
        <h2 class="landing-mobius-h2">
          Почему хорошее<br />ТЗ еще не означает<br />хороший UI?
        </h2>
      </div>
    </div>
    <!-- <div class="landing-random-coffee">
      <icon-chat />
      <h4 class="random-coffee-title">
        Random Coffee с участниками конференции
      </h4>
      <p class="landing-random-coffee-title">
        Неформальное общение один на один с близким по духу собеседником
      </p>
      <a
        href="https://t.me/CrocRandomCoffeeBot"
        target="_blank"
        class="btn btn--green mt-32 btn--size-lg uppercase"
      >
        <p>Найти собеседника</p>
        <span></span>
      </a>
    </div> -->
  </div>
</template>

<script>
// import IconChat from '../Icons/IconChat.vue';

export default {
  name: 'LandingVacancied',
  // components: { IconChat },
  data() {
    return {
      speakers: [
        {
          name: 'Екатерина Кучина',
          position: '',
          photo: 'speaker-1.png',
        },
        {
          name: 'Светлана Астанина',
          position: '',
          photo: 'speaker-1.png',
        },
        {
          name: 'Наталья Букреева',
          position: '',
          photo: 'speaker-1.png',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.my-border {
  border-left: 1px solid white;
  margin: 0 30px;
  margin-right: 70px;
}
.bottom-border {
  border-bottom: 1px solid #fff;
}
@media screen and (max-width: 968px) {
  .bottom-border {
    border-bottom: none;
  }
  .my-border {
    border-left: none;
    border-top: 1px solid white;
    // padding-top: 20px;
    margin: 50px 0px;
  }
}
.speaker {
  @apply inline-block text-14 leading-18 pl-60 pr-24 relative font-croc-light;
  color: rgba(255, 255, 255, 0.8);
  max-width: 170px;
  &__photo {
    @apply absolute top-0 left-0 rounded-full;
    width: 48px;
    height: 48px;
  }
}
.landing-random-coffee {
  @apply lg:pl-76 mt-24 sm:mt-60 lg:mt-0 box-content;
  width: 383px;
  flex-shrink: 0;
  &-title {
    @apply text-14 sm:text-18 leading-22 sm:leading-28 font-croc-light;
    color: rgba(255, 255, 255, 0.7);
  }
}
.landing-mobius-header {
  @apply text-14 sm:text-24 font-croc-bold leading-24 sm:leading-36 mb-16 sm:mb-20
    uppercase;
  color: #CDCDCD;
}
.landing-mobius-h2 {
  @apply text-18 leading-28 sm:text-24 xl:text-24;
  // border: 1px solid red;
  // max-width: 606px;
}
.random-coffee-title {
  @apply uppercase text-16 leading-26 sm:text-18 xl:text-32 sm:leading-32 mb-8 mt-24;
}
</style>
