<!-- eslint-disable max-len -->
<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.8">
      <rect
        x="0.5"
        y="0.5"
        width="47"
        height="47"
        rx="3.5"
        fill="#00A460"
        fill-opacity="0.05"
      />
      <path
        d="M27.9969 15.985H30.1879V12.169C29.8099 12.117 28.5099 12 26.9959 12C23.837 12 21.673 13.987 21.673 17.639V21H18.187V25.266H21.673V36H25.9469V25.267H29.2919L29.8229 21.001H25.946V18.062C25.947 16.829 26.2789 15.985 27.9969 15.985Z"
        fill="#23E191"
      />
      <rect
        x="0.5"
        y="0.5"
        width="47"
        height="47"
        rx="3.5"
        stroke="url(#paint0_linear_12:37441)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_12:37441"
        x1="-5.47826"
        y1="29"
        x2="54.2609"
        y2="29"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#23E191" stop-opacity="0.9" />
        <stop offset="0.520349" stop-color="#181818" />
        <stop offset="1" stop-color="#21EB96" stop-opacity="0.9" />
        <stop offset="1" stop-color="#23E191" stop-opacity="0.9" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconFb',
  components: {},
  data() {
    return {};
  },
};
</script>
