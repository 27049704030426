<template>
  <div class="dialog" :class="className">
    <div class="dialog-npc" v-if="withNpc"></div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Dialog',
  data() {
    return {};
  },
  props: {
    withNpc: { type: Boolean, default: false },
    className: { type: String },
  },
};
</script>

<style lang="scss">
$border: 1px;

.dialog {
  width: 100%;
  min-height: 388px;
  &.green-blue {
    background: linear-gradient(35.5deg, #1A213A -3.15%, #0D2E20 83.76%);
    &::before {
    background: linear-gradient(to right, #475DEB, #00A460);
    }
  }
  &.without-border {
    background: none;
    &::after {
      border: none;
    }
    &::before {
      border: none;
      background: none;
    }
  }
  &.blue {
    background: linear-gradient(
        0deg,
        rgba(71, 93, 235, 0.1),
        rgba(71, 93, 235, 0.1)
      ),
      #101010;
    // background-position-y: 50px;
    border: 1px solid rgba(0, 175, 214, 1);
    position: relative;
    &::before {
      background: none;
      // border: 1px solid red;
    }
  }
  &.red {
    background: linear-gradient(
        0deg,
        rgba(71, 93, 235, 0.1),
        rgba(71, 93, 235, 0.1)
      ),
      #101010;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    border: 1px solid #FF645A;
    &::before {
      background: none;
    }
  }
  @apply flex items-center relative text-white px-16 pb-64;
  border-radius: 10px;
  background: linear-gradient(
      90.55deg,
      rgba(255, 100, 90, 0) 0.43%,
      rgba(0, 164, 96, 0.05) 39.67%
    ),
    #101010;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 10px;
    padding: 1px;
    background: linear-gradient(to right, #00a460, #00a460);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  &-npc {
    @apply absolute bg-contain bg-no-repeat bg-center;
    width: 70px;
    height: 100px;
    background-image: url('/images/npc-1.png');
    left: 50%;
    transform: translate(-50%, 0);
    top: -30px;
    -webkit-filter: drop-shadow(0px 0px 30px rgba(35, 225, 145, 0.6));
    filter: drop-shadow(0px 0px 30px rgba(35, 225, 145, 0.6));
  }
}
</style>
