<!-- eslint-disable max-len -->
<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.8">
      <rect
        x="0.5"
        y="0.5"
        width="47"
        height="47"
        rx="3.5"
        fill="#00A460"
        fill-opacity="0.05"
      />
      <path
        d="M31.915 25.028C31.527 24.538 31.638 24.32 31.915 23.882C31.92 23.877 35.123 19.451 35.453 17.95L35.455 17.949C35.619 17.402 35.455 17 34.662 17H32.038C31.37 17 31.062 17.345 30.897 17.731C30.897 17.731 29.561 20.929 27.671 23.002C27.061 23.601 26.779 23.793 26.446 23.793C26.282 23.793 26.027 23.601 26.027 23.054V17.949C26.027 17.293 25.84 17 25.287 17H21.161C20.742 17 20.493 17.306 20.493 17.591C20.493 18.213 21.438 18.356 21.536 20.106V23.903C21.536 24.735 21.385 24.888 21.05 24.888C20.158 24.888 17.993 21.677 16.71 18.002C16.451 17.289 16.198 17.001 15.525 17.001H12.9C12.151 17.001 12 17.346 12 17.732C12 18.414 12.892 21.805 16.148 26.285C18.318 29.343 21.374 31 24.154 31C25.825 31 26.029 30.632 26.029 29.999C26.029 27.077 25.878 26.801 26.715 26.801C27.103 26.801 27.771 26.993 29.331 28.468C31.114 30.217 31.407 31 32.405 31H35.029C35.777 31 36.156 30.632 35.938 29.906C35.439 28.379 32.067 25.238 31.915 25.028Z"
        fill="#23E191"
      />
      <rect
        x="0.5"
        y="0.5"
        width="47"
        height="47"
        rx="3.5"
        stroke="url(#paint0_linear_12:37444)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_12:37444"
        x1="-5.47826"
        y1="29"
        x2="54.2609"
        y2="29"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#23E191" stop-opacity="0.9" />
        <stop offset="0.520349" stop-color="#181818" />
        <stop offset="1" stop-color="#21EB96" stop-opacity="0.9" />
        <stop offset="1" stop-color="#23E191" stop-opacity="0.9" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconVk',
  components: {},
  data() {
    return {};
  },
};
</script>
