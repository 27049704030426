<template>
  <Button
    is-has-bg
    is-text-colored
    is-uppercase
    is-big-radius
    is-full-width
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
    tag="a"
    :href="authUrl"
  >
    <slot v-if="!!$slots.default"/>
    <template v-else>
      Начать
    </template>
    <template #icon>
      <img :src="isHovered ? $options.geeckoIdHover : $options.geeckoId" />
    </template>
  </Button>
</template>

<script>
import geeckoId from '@/assets/images/geeckoId.svg';
import geeckoIdHover from '@/assets/images/geeckoIdHover.svg';

import Button from '@/components/UI/Button.vue';

export default {
  geeckoId,
  geeckoIdHover,
  data() {
    return {
      isHovered: false,
    };
  },
  computed: {
    authUrl() {
      return `${process.env.VUE_APP_SERVER_URL}/oauth/redirect`;
    },
  },
  components: {
    Button,
  },
};
</script>
