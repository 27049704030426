<template>
  <div class="vacancies">
    <h2 class="landing-h2">Вакансии для тебя</h2>
    <div class="vacancy__container">
      <div class="vacancy justify-between" v-for="(vacancy, id) in vacancies" :key="id">
        <div class="content">
          <div class="text-24 leading-28 mb-12">{{ vacancy.name }}</div>
          <div class="vacancy__descr text-14 mr-12">
            {{ `${vacancy.experience}` }}
          </div>
        </div>
        <div class="flex flex-row flex-wrap items-center">
          <div class="-m-4 mt-6 pb-16 w-full">
            <div
              class="inline-block m-4 text-12 leading-18 py-8 px-8 opacity-70 border rounded-4"
              v-for="lang in vacancy.languages"
              :key="lang"
            >
              {{ lang }}
            </div>
          </div>
          <a
            href="tg://resolve?domain=zhukovas"
            target="_blank"
            class="btn btn--green mt-16 uppercase"
          >
            <p>Связаться</p>
            <span></span>
          </a>
          <a
            :href="vacancy.link"
            target="_blank"
            class="mt-16 text-green text-12 leading-18 ml-16"
            >Подробнее</a
          >
        </div>
      </div>
      <div class="recruiter lg:ml-20">
        <h2 class="text-24">Наши рекрутеры</h2>
        <div class="speaker">
          <!-- <img class="speaker__photo" :src="`/images/speaker-1.png`" /> -->
          <div class="flex flex-col">
            <p>Светлана Жукова</p>
            <!-- eslint-disable -->
            <a href="tg://resolve?domain=zhukovas">
              <svg style="margin-right: 10px;" width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.7611 0.857676C18.5954 0.714515 18.3936 0.619441 18.1777 0.582754C17.9618 0.546066 17.7399 0.569165 17.5362 0.649547L1.6758 6.88043C1.43391 6.97547 1.22928 7.14627 1.09255 7.36729C0.955824 7.58832 0.894341 7.84767 0.917304 8.10655C0.940267 8.36543 1.04644 8.60992 1.21994 8.80342C1.39344 8.99693 1.62493 9.12905 1.87978 9.18002L6.04657 10.0133V15.1862C6.04636 15.4243 6.1168 15.6571 6.24897 15.855C6.38114 16.053 6.56909 16.2073 6.78902 16.2984C7.00896 16.3895 7.25098 16.4133 7.48443 16.3667C7.71788 16.3202 7.93226 16.2054 8.10043 16.0369L10.4439 13.6934L13.9489 16.7778C14.1669 16.9714 14.4482 17.0785 14.7397 17.0788C14.8664 17.0787 14.9923 17.0587 15.1129 17.0196C15.3117 16.9566 15.4906 16.8424 15.6316 16.6887C15.7725 16.5349 15.8708 16.3469 15.9164 16.1433L19.1488 2.03814C19.198 1.82473 19.1879 1.60193 19.1196 1.39385C19.0512 1.18577 18.9273 1.00035 18.7611 0.857676ZM1.94453 8.01541C1.93896 7.97813 1.94679 7.94008 1.96663 7.90803C1.98647 7.87598 2.01702 7.852 2.05287 7.84035L16.8748 2.03814L6.44269 9.04089L2.08199 8.16874C2.04464 8.16358 2.01033 8.14534 1.98516 8.11727C1.95999 8.08919 1.94559 8.0531 1.94453 8.01541ZM7.37122 15.3078C7.34719 15.3318 7.31656 15.3482 7.28322 15.3548C7.24989 15.3614 7.21533 15.358 7.18392 15.345C7.15251 15.332 7.12567 15.31 7.10679 15.2817C7.0879 15.2535 7.07782 15.2202 7.07782 15.1862V10.7312L9.6682 13.0108L7.37122 15.3078ZM14.9112 15.9129C14.9045 15.942 14.8905 15.9688 14.8703 15.9907C14.8502 16.0127 14.8247 16.029 14.7964 16.0382C14.7681 16.0473 14.7378 16.0489 14.7087 16.0429C14.6795 16.0368 14.6524 16.0233 14.6301 16.0037L7.38784 9.63041L17.7915 2.58331L14.9112 15.9129Z" fill="#23E191"/>
              </svg>
              @zhukovas</a>
          </div>
        </div>
        <div class="speaker">
          <!-- <img class="speaker__photo" :src="`/images/speaker-1.png`" /> -->
          <div class="flex flex-col">
            <p>Александра Русакова</p>
            <!-- eslint-disable -->
            <a href="tg://resolve?domain=shu_ches">
              <svg style="margin-right: 10px;" width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.7611 0.857676C18.5954 0.714515 18.3936 0.619441 18.1777 0.582754C17.9618 0.546066 17.7399 0.569165 17.5362 0.649547L1.6758 6.88043C1.43391 6.97547 1.22928 7.14627 1.09255 7.36729C0.955824 7.58832 0.894341 7.84767 0.917304 8.10655C0.940267 8.36543 1.04644 8.60992 1.21994 8.80342C1.39344 8.99693 1.62493 9.12905 1.87978 9.18002L6.04657 10.0133V15.1862C6.04636 15.4243 6.1168 15.6571 6.24897 15.855C6.38114 16.053 6.56909 16.2073 6.78902 16.2984C7.00896 16.3895 7.25098 16.4133 7.48443 16.3667C7.71788 16.3202 7.93226 16.2054 8.10043 16.0369L10.4439 13.6934L13.9489 16.7778C14.1669 16.9714 14.4482 17.0785 14.7397 17.0788C14.8664 17.0787 14.9923 17.0587 15.1129 17.0196C15.3117 16.9566 15.4906 16.8424 15.6316 16.6887C15.7725 16.5349 15.8708 16.3469 15.9164 16.1433L19.1488 2.03814C19.198 1.82473 19.1879 1.60193 19.1196 1.39385C19.0512 1.18577 18.9273 1.00035 18.7611 0.857676ZM1.94453 8.01541C1.93896 7.97813 1.94679 7.94008 1.96663 7.90803C1.98647 7.87598 2.01702 7.852 2.05287 7.84035L16.8748 2.03814L6.44269 9.04089L2.08199 8.16874C2.04464 8.16358 2.01033 8.14534 1.98516 8.11727C1.95999 8.08919 1.94559 8.0531 1.94453 8.01541ZM7.37122 15.3078C7.34719 15.3318 7.31656 15.3482 7.28322 15.3548C7.24989 15.3614 7.21533 15.358 7.18392 15.345C7.15251 15.332 7.12567 15.31 7.10679 15.2817C7.0879 15.2535 7.07782 15.2202 7.07782 15.1862V10.7312L9.6682 13.0108L7.37122 15.3078ZM14.9112 15.9129C14.9045 15.942 14.8905 15.9688 14.8703 15.9907C14.8502 16.0127 14.8247 16.029 14.7964 16.0382C14.7681 16.0473 14.7378 16.0489 14.7087 16.0429C14.6795 16.0368 14.6524 16.0233 14.6301 16.0037L7.38784 9.63041L17.7915 2.58331L14.9112 15.9129Z" fill="#23E191"/>
              </svg>
              @shu_ches</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LandingVacancied',
  data() {
    return {
      vacancies: [
        {
          name: 'Ведущий системный аналитик',
          experience: 'Москва · Можно удаленно',
          languages: ['SQL', 'XML', 'UML', 'BPMN', 'REST'],
          link:
            'https://career.habr.com/vacancies/1000088475',
        },
        {
          name: 'Системный аналитик',
          experience: 'Москва · Можно удаленно',
          languages: ['BPMN', 'XML', 'Разработка ТЗ', 'Английский язык'],
          link:
            'https://career.habr.com/vacancies/1000088543',
        },
        // {
        //   name: 'Middle/Senior Android',
        //   experience: '2-3 года',
        //   languages: ['Java', 'Kotlin'],
        //   link:
        //     'https://www.croc.ru/vacancy/middle-senior-android-developer/?utm_source=mobius&utm_medium=landingpage',
        // },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.recruiter {
  @apply flex flex-col items-start space-y-20;
  // padding-left: 20px;
  color: white;
  p {
    font-size: 18px;
  }
}
.vacancies {
  @apply mt-32 sm:mt-40;
}
.speaker {
  @apply inline-block text-14 leading-18 pr-24 relative font-croc-light;
  color: rgba(255, 255, 255, 0.8);
  // max-width: 170px;
  a {
    color: #23E191;
    @apply flex flex-row;
    margin-top: 10px;
  }
  &__photo {
    @apply absolute top-0 left-0 rounded-full;
    width: 48px;
    height: 48px;
  }
}
.vacancy {
  @apply flex flex-col;
  &__container {
    @apply w-full sm:flex flex-wrap justify-start -mx-20 xl:-mx-28;
  }
  @apply p-16 mb-24 sm:mb-44 mx-0 xl:mx-20;
  line-height: 0;
  background-color: rgba(27, 27, 27, 1);
  border-radius: 15px;
  &__descr {
    @apply inline-block text-14 leading-18 mb-16;
    color: rgba(255, 255, 255, 0.8);
  }
}
@media screen and (max-width: 768px) {
  .vacancies {
    padding: 0;
    margin: 0;
  }
  .vacancy {
    &__container {
      // border: 1px solid red;
      @apply w-full flex justify-center;
      max-width: 100%;
      margin: 0;
    }
    // border: 1px solid red;
    @apply w-full;
  }
}
@screen sm {
  .vacancy {
    width: calc(100% / 3 - 40px);
    min-width: 400px;
  }
}
@screen lg {
  .vacancy {
    min-width: 0;
  }
}
@screen xl {
  .vacancy {
    width: calc(100% / 3);
  }
}
</style>
