<template>
  <div class="conversation">
    <!-- <span>{{reversedKeys}}</span> -->
    <Card ref="dialogCards">
      <Dialog
        :withNpc="!isLastMessage"
        :class="`${isLastMessage ? 'green-blue' : 'without-border'}`"
      >
        <div class="conversation-content" v-if="currentMsg">
          <div class="conversation-content__subtitle" v-if="currentMsg.subtitle">
            {{ currentMsg.subtitle }}
          </div>
          <div class="conversation-content__title" v-if="currentMsg.title">
            {{ currentMsg.title }}
          </div>
          <div class="conversation-content__text" v-if="currentMsg.text">
            {{ currentMsg.text }}
          </div>
          <img
            v-if="currentMsg.image"
            :src="`/images/${currentMsg.image}`"
            class="conversation-content__image"
          />
        </div>
      </Dialog>
    </Card>
    <div class="flex-1" />
    <div
      v-if="currentMsg && currentMsg.answers.length"
      class="conversation-buttons" ref="dialogButtons">
      <Button
        is-full-width
        v-for="(answer, idx) in currentMsg.answers"
        :key="`'answer-${idx}'`"
        :is-blue="idx === 1"
        @click="next(answer.next)"
        :is-next="currentMsg.answers.length === 1"
      >
        {{ answer.text }}
      </Button>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';

import Button from '@/components/UI/Button.vue';
import Dialog from './Dialog.vue';
import Card from './Card.vue';

export default {
  name: 'Conversation',
  components: { Dialog, Button, Card },
  data() {
    return {
      current: 1,
      currentIndex: 1,
    };
  },
  props: {
    content: { type: Object, default: () => {} },
    endOfDialog: { type: Function },
  },
  computed: {
    currentMsg() {
      return this.content && this.content[this.current];
    },
    fullLength() {
      return Object.keys(this.content).length;
    },
    isLastMessage() {
      return this.current === 'last';
    },
    reversedKeys() {
      return Object.keys(this.content).reverse();
    },
  },
  mounted() {
    // this.$refs.dialogCards.forEach((c, i) => {
    //   c.appear(() => {
    //   }, 0.25 * (i + 1));
    // });
    // this.cardAppear(250 * this.$refs.dialogCards.length);
    this.showButtons();
  },
  methods: {
    getCardStyle(i) {
      return {
        position: 'absolute',
        top: `${(i + 1) * 5}px`,
      };
    },
    showButtons() {
      gsap.to(this.$refs.dialogButtons, {
        duration: 0.35,
        autoAlpha: 1,
      });
    },
    cardAppear(duration = 0) {
      setTimeout(() => {
        const currentCard = this.$refs.dialogCards.find(
          (r) => r.$vnode.key === this.current.toString(),
        );
        currentCard.flip();
        this.showButtons();
      }, duration);
    },
    next(id) {
      if (id === 'end') {
        this.endOfDialog();
      } else {
        // gsap.to(this.$refs.dialogButtons, {
        //   duration: 0.35,
        //   autoAlpha: 0,
        // });
        // if (this.currentMsg.answers.length > 1) {
        //   let blocked = [];
        //   if (id === 2) {
        //     blocked = [3];
        //   } else if (id === 3) {
        //     blocked = [2];
        //   } else if (id === 6) {
        //     blocked = [5, 7];
        //   } else if (id === 5) {
        //     blocked = [6];
        //   }
        //   blocked.forEach((b) => {
        //     const blockCard = this.$refs.dialogCards.find(
        //       (r) => r.$vnode.key === b.toString(),
        //     );
        //     blockCard.disappear(() => {
        //     }, 2);
        //   });
        // }
        // const currentCard = this.$refs.dialogCards.find(
        //   (r) => r.$vnode.key === this.current.toString(),
        // );
        this.current = id;
        // currentCard.disappear(() => {
        //   this.current = id;
        //   this.$nextTick(() => {
        //     this.cardAppear();
        //   });
        // });
      }
    },
  },
};
</script>

<style lang="scss">
.conversation {
  @apply relative z-0 flex flex-col justify-between pb-16;
  height: 100%;
  // border: 1px solid red;
  &-content {
    @apply text-center mx-auto;
    &__subtitle {
      @apply text-green text-12 leading-18 mb-12;
    }
    &__title {
      @apply text-24 leading-32 mb-16 uppercase;
    }
    &__text {
      @apply text-16 leading-26 font-croc-regular;
    }
    &__image {
      @apply mt-36 mx-auto;
    }
  }

  &-buttons {
    button {
      margin-top: 10px !important;
    }
    opacity: 0;
  }
}
</style>
