<template>
  <div class="game-container">
    <div class="green-blur"></div>
    <div class="game">
      <game-header v-if="true"/>
      <intro v-if="stage === 'intro'" />
      <geecko-enter v-else-if="stage === 'enter'" />
      <game v-else-if="stage === 'game'" />
      <game-result v-if="false" />
      <modal-settings />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Game from '../components/Game.vue';
import GameResult from '../components/GameResult.vue';
import GeeckoEnter from '../components/GeeckoEnter.vue';
import Intro from '../components/Intro.vue';
import GameHeader from '@/components/GameHeader.vue';
import ModalSettings from '@/components/Modals/ModalSettings.vue';

export default {
  name: 'GameFlow',
  components: {
    Game,
    GameResult,
    GeeckoEnter,
    Intro,
    GameHeader,
    ModalSettings,
  },
  computed: {
    user() {
      return this.$store.getters['user/user'];
    },
    stage() {
      if (!this.user?.id) {
        return this.isIntroShown ? 'enter' : 'intro';
      }
      return 'game';
    },
    isIntroShown() {
      return this.$store.state.user.isIntroShown;
    },
    isCounterActive() {
      return this.$store.state.game.showFinal;
    }
  },
  watch: {},
  async mounted() {
    // // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    // const vh = window.innerHeight * 0.01;
    // // Then we set the value in the --vh custom property to the root of the document
    // this.$refs.game.style.setProperty('--vh', `${vh}px`);
    // // We listen to the resize event
    // window.addEventListener('resize', () => {
    //   // We execute the same script as before
    //   const newVh = window.innerHeight * 0.01;
    //   this.$refs.game.style.setProperty('--vh', `${newVh}px`);
    // });
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  height: 100%;
  background-image: url('/images/landing-matrix-game.png');
  background-size: 1800px;
  width: 100%;
  padding: 0;
  background-repeat: repeat no-repeat;
  background-position: bottom center;
  @apply flex flex-row justify-center items-center overflow-hidden;
  
}
.game {
  @apply relative bg-gray-900;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  width: 100%;
  padding: 0px 10px;
  max-width: 100%;
}
.green-blur {
  background: none;
  width: 100%;
  position: absolute;
}

@screen sm {
  .game-container {
    // max-height: 670px;
    max-height: 100vh;
  }
  .game {
    max-height: 736px;
    max-width: 414px;
    position: relative;
    // background: black;
    @apply bg-gray-900;
    z-index: 2;
  }
  .green-blur {
    @apply absolute bottom-0 w-full;
    z-index: 1;
    max-height: 100%;
    max-width: 100%;
    height: 100%;
    // bottom: 50px;
    background: url('/images/green-blur.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom;
    // background-position-y: -50px;
  }
}
</style>
