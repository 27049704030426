<template>
  <div class="game-layout" :class="bodyClass">
    <!-- <button class="absolute top-0 left-0 bg-red p-12" @click="resetGame()">RESET</button> -->
    <!-- <hud-panel v-if="isHudShow" :interval="interval" :isActive="isHudActive"></hud-panel> -->
    <router-view></router-view>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */

// import HudPanel from '@/components/HudPanel.vue';

export default {
  name: 'Game',
  components: {
    // HudPanel,
  },
  data() {
    return {
      interval: 30,
      bodyClass: null,
    };
  },
  computed: {
    isHudActive() {
      return this.$store.state.isHudActive;
    },
    user() {
      return this.$store.getters['user/user'];
    },
    stage() {
      if (!this.user?.id) {
        return this.isIntroShown ? 'enter' : 'intro';
      }
      return 'game';
    },
    isIntroShown() {
      return this.$store.state.user.isIntroShown;
    },
    isHudShow() {
      return this.$store.state.isHudShow;
    },
  },
  methods: {
    async resetGame() {
      await this.$store.dispatch('game/restartGame');
      window.location.reload();
    },
  },
  async mounted() {
    // await this.$store.dispatch('user/fetchUser');
    // await this.$store.dispatch('game/fetchGame', true);
    // this.$store.dispatch('game/restartGame');
  },
  // beforeRouteEnter(to, from, next) {
  //   next((vm) => {
  //     if (to.meta && to.meta.bodyClass) {
  //       vm.bodyClass = to.meta.bodyClass;
  //     } else {
  //       vm.bodyClass = null;
  //     }
  //   });
  // },
  // beforeRouteUpdate(to, from, next) {
  //   next((vm) => {
  //     if (to.meta && to.meta.bodyClass) {
  //       vm.bodyClass = to.meta.bodyClass;
  //     } else {
  //       vm.bodyClass = null;
  //     }
  //   });
  // },
};
</script>

<style lang="scss">
.game-layout {
  @apply bg-top relative w-full h-screen;
  &--bg {
    padding-top: 120px;
    background-repeat: repeat no-repeat, no-repeat;
    background-size: 320px 46px, contain;
    background-image: linear-gradient(
        180deg,
        #101010 0%,
        rgba(16, 16, 16, 0) 100%
      ),
      url('/images/game-matrix.svg');
  }
}

</style>
