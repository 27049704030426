<template>
  <div class="result">
    <template v-if="hasPhone">
      <div class="result__title">Ты пробудился и занял</div>
      <div class="result__position">{{ gameInfo.rank }} место</div>
      <p>
        Приглашаем попробовать свои силы в Croc Code.
        Наши двери открыты как для сильных скилловых спецов, так и для подающих надежды джунов,
        готовых развиваться в области программирования.
      </p>
      <croc-social-network-links />
      <div class="result__social-text">Croc Code в соцсетях</div>
      <Button is-full-width is-blue is-uppercase
      style="margin-bottom: 60px;"
      @click="$emit('click')">Начать игру с начала</Button>
      <Button class="z-50" is-full-width is-uppercase
      @click="goLanding">На главную</Button>
    </template>
    <template v-else>
      <div class="text-24 leading-32 uppercase mb-8">
        Введи свой номер телефона
      </div>
      <div class="text-green text-12 leading-18 uppercase mb-32">
        для того, чтобы принять участие в розыгрыше призов, требуется твой номер
        телефона
      </div>
      <label class="geecko-label">Телефон:</label>
      <input-mask
        v-model="phone"
        mask="+7 (999) 999-99-99"
        mask-char=" "
        placeholder="+7 (999) 999-99-99"
        class="geecko-input"
        type="text"
        required
      />
      <Button is-full-width is-uppercase @click="handleSendPhone" :disabled="!phoneComplete"
        >Отправить</Button
      >
    </template>
  </div>
</template>

<script>
import Button from '@/components/UI/Button.vue';
import CrocSocialNetworkLinks from './CrocSocialNetworkLinks.vue';

export default {
  name: 'GameResult',
  components: { CrocSocialNetworkLinks, Button },
  data() {
    return {
      phone: '',
      phoneSent: false,
    };
  },
  props: {
    next: {
      type: Function,
      // required: true,
    },
  },
  methods: {
    handleSendPhone() {
      try {
        this.$store.dispatch('user/updateUser', { phone: this.phone });
      } catch (e) {
        console.log('[send-phone] error', e);
      }
    },
    async goLanding() {
      await this.$store.commit('user/SET_TOKEN', null);
      this.$router.push('/');
    },
  },
  computed: {
    user() {
      return this.$store.getters['user/user'];
    },
    hasPhone() {
      return this.user?.phone;
    },
    gameInfo() {
      return this.$store.state.game.gameInfo;
    },
    phoneComplete() {
      return this.phone && this.phone.trim().length === 18;
    },
  },
  mounted() {
    this.$store.dispatch('game/fetchGame');
  },
};
</script>

<style lang="scss" scoped>
.result {
  @apply text-center pb-72 px-12 pt-8 flex flex-col justify-center relative
    bg-no-repeat text-white;
  background-size: contain;
  background-position: top, bottom, top;
  background-image: linear-gradient(
      0deg,
      rgba(16, 16, 16, 0) 0%,
      rgba(16, 16, 16, 0.76) 50%,
      #101010 100%
    ),
    linear-gradient(
      180deg,
      rgba(16, 16, 16, 0) 0%,
      rgba(16, 16, 16, 0.76) 50%,
      #101010 100%
    );
  height: 100%;
  &__title {
    @apply font-croc-regular text-18 leading-28 uppercase;
  }
  &__position {
    @apply font-croc-bold text-green text-32 leading-48 mt-4 uppercase;
  }
  & p {
    @apply text-16 leading-26 mt-20 font-croc-light mb-52;
  }
  &__next-btn {
    @apply uppercase absolute bottom-16;
    width: calc(100% - 24px);
  }
  & .social-container {
    margin: 0 auto;
  }
  &__social-text {
    @apply text-gray-100 text-12 leading-18 mt-12;
  }
  & .croc-button {
    @apply absolute bottom-12;
    width: calc(100% - 24px);
  }
}
.geecko-input {
  @apply text-18 leading-28 font-croc-regular px-16 focus:border-green focus:outline-none;
  background: #101010;
  border: 1px solid rgba(255, 255, 255, 0.3);
  height: 52px;
}
.geecko-label {
  @apply text-12 leading-18 font-croc-light mb-8 text-left;
}
</style>
