<template>
  <div class="code-letter-outer">
    <div class="code-letter">
      <slot v-if="!!$slots.default" />
      <img v-else :src="$options.key" />
    </div>
  </div>
</template>

<script>
import key from '@/assets/images/key.svg';

export default {
  key,
};
</script>

<style lang="scss" scoped>
.code-letter-outer {
  @apply h-36 w-36 p-4;
  border: 0.563758px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
}

.code-letter {
  @apply text-green text-18 leading-28 font-croc-bold w-full h-full;
  @apply flex items-center justify-center;
  background: rgba(0, 164, 96, 0.05);
  border: 0.563758px solid rgba(0, 164, 96, 0.5);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
