<!-- eslint-disable max-len -->
<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.8">
      <rect
        x="0.5"
        y="0.5"
        width="47"
        height="47"
        rx="3.5"
        fill="#00A460"
        fill-opacity="0.05"
      />
      <g clip-path="url(#clip0_12:37452)">
        <path
          d="M21.4171 27.181L21.0201 32.765C21.5881 32.765 21.8341 32.521 22.1291 32.228L24.7921 29.683L30.3101 33.724C31.3221 34.288 32.0351 33.991 32.3081 32.793L35.9301 15.821L35.9311 15.82C36.2521 14.324 35.3901 13.739 34.4041 14.106L13.1141 22.257C11.6611 22.821 11.6831 23.631 12.8671 23.998L18.3101 25.691L30.9531 17.78C31.5481 17.386 32.0891 17.604 31.6441 17.998L21.4171 27.181Z"
          fill="#23E191"
        />
      </g>
      <rect
        x="0.5"
        y="0.5"
        width="47"
        height="47"
        rx="3.5"
        stroke="url(#paint0_linear_12:37452)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_12:37452"
        x1="-5.47826"
        y1="29"
        x2="54.2609"
        y2="29"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#23E191" stop-opacity="0.9" />
        <stop offset="0.520349" stop-color="#181818" />
        <stop offset="1" stop-color="#21EB96" stop-opacity="0.9" />
        <stop offset="1" stop-color="#23E191" stop-opacity="0.9" />
      </linearGradient>
      <clipPath id="clip0_12:37452">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(12 12)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconTg',
  components: {},
  data() {
    return {};
  },
};
</script>
