<template>
  <form class="code-enter" @submit.prevent="submit">
    <div>
      <div class="code-enter__title">Введи код</div>
      <div
        class="code-input__container"
        v-for="(letter, idx) in codes"
        :key="`code-${idx}`"
      >
        <input
          @input="change(idx)"
          maxlength="1"
          type="text"
          class="code-input"
          :class="{ failure: failure.includes(idx) }"
          v-model="codes[idx]"
          :disabled="!!initialCodes[idx]"
          placeholder="_"
          style="text-transform: uppercase"
          ref="codeInput"
        />
      </div>
      <p>
        {{ question }}
      </p>
    </div>
    <Button is-full-width is-uppercase type="submit" :disabled="submitDisabled"
      >Ввести код</Button
    >
  </form>
</template>

<script>
import Button from '@/components/UI/Button.vue';

export default {
  name: 'CodeEnter',
  components: { Button },
  data() {
    return {
      codes: [],
      // code from api
      initialCodes: [null, null, null, null],
      failure: [],
      question: '',
    };
  },
  methods: {
    change(idx) {
      if (!this.codes[idx].trim().length) {
        this.codes[idx] = null;
      }
      this.failure = this.failure.filter((item) => item !== idx);
      if (idx < this.codes.length) {
        for (let i = idx; i < this.codes.length; i += 1) {
          if (!this.codes[i]) {
            this.$refs.codeInput[i].focus();
            break;
          }
        }
      }
    },
    async submit() {
      try {
        const response = await this.$http.post(
          '/game/answer-password-question',
          {
            code: this.codes.join('').toUpperCase(),
          },
        );
        if (response.data.result) {
          await this.$store.dispatch('game/fetchGame');
        } else {
          this.initialCodes = response.data.codes;
          this.failure = Array.from(this.initialCodes.entries())
            .filter(([, val]) => !val)
            .map((item) => item[0]);
        }
      } catch (e) {
        console.log('[code-enter] error', e);
      }
    },
  },
  watch: {
    initialCodes(val) {
      this.codes = [...val];
    },
  },
  computed: {
    submitDisabled() {
      return this.codes.some((item) => !item);
    },
  },
  async created() {
    try {
      const response = await this.$http.get('/game/get-password-question');
      if (response.data && response.data.codes) {
        this.initialCodes = response.data.codes;
        this.codes = [...this.initialCodes];
        this.question = response.data.question;
      }
    } catch (e) {
      console.log('[code-enter] error', e);
    }
  },
};
</script>

<style lang="scss" scoped>
.code-enter {
  @apply relative flex flex-col justify-center text-center pb-72;
  height: 100%;
  color: white;
  & .croc-button {
    @apply absolute bottom-12;
  }
  &__title {
    @apply uppercase text-28 leading-36 mb-20;
  }
  & p {
    @apply mt-24 text-14 leading-24 font-croc-regular;
  }
}
.code-input {
  &__container {
    @apply inline-block m-4 p-8;
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 59px;
    height: 64px;
  }
  @apply align-top inline-block w-full h-full text-32 leading-48 text-green text-center border
    border-green;
  background: rgba(0, 164, 96, 0.15);
  &:disabled {
    opacity: 0.4;
  }
  &::placeholder {
    @apply text-green text-left pl-8;
  }
  &.failure {
    @apply border-red;
    background: rgba(255, 100, 90, 0.15);
    &::placeholder {
      @apply text-red;
    }
  }
}
</style>
