<style lang="scss">
.g-game-settings {
  @apply font-croc-bold w-full h-full p-12 text-left;
  &.dark {
    --bg-color: #232425;
    --font-color: #ffffff;
    --secondary-color: rgba(255, 255, 255, 0.5);
    --link-hover-color: rgba(255, 255, 255, 0.7);
    --divider-color: #3d3c3d;
    --link-decoration: underline;
    --border-color: #3d3c3d;
  }
  --black-color: #1a1b35;
  --primary-color: #1a348f;
  --gray-color: #858a9c;

  background: var(--bg-color, #ffffff);

  &__geecko-logo {
    width: 75px;
    height: 16px;
    margin-bottom: 24px;
  }

  &__title {
    @apply text-32 mb-12;
    color: var(--font-color, var(--black-color));
  }
  &__auth-title {
    @apply text-12 mb-4;
    color: var(--secondary-color, var(--gray-color));
  }
  &__geecko-profile {
    @apply relative flex cursor-pointer box-border justify-center flex-col;
    height: 64px;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 8px;
    padding-right: 8px;
    border: 1px solid var(--border-color, #edeef0);
    border-radius: 4px;
    transition: 0.25s;
    &:hover {
      background: #131313;
      & .g-game-settings__geecko-profile-open {
        transform: scale(1.05);
      }
      & .g-game-settings__geecko-profile-name {
        color: white;
      }
    }
    &-open {
      @apply absolute;
      right: 5px;
      top: 5px;
      color: var(--gray-color);
    }
    &-photo {
      @apply absolute bg-contain bg-center bg-no-repeat;
      --size: 32px;
      width: var(--size);
      height: var(--size);
      border-radius: 2px;
      background-color: #f0f3fc;
      top: calc(50% - var(--size) / 2);
    }
    &-name {
      @apply leading-24 text-14;
      color: white;
      padding-left: 60px;
    }
    &-nickname {
      letter-spacing: 0.01em;
    }
  }
  &__lang {
    &-title {
      color: #101014;
    }
    &-subtitle {
      color: #4d4e57;
    }
  }
  &__divider {
    background: var(--divider-color, #edeef0);
    height: 1px;
  }
  &__doc {
    &-container {
    }
    &-item {
      @apply inline-block;
      color: var(--secondary-color, var(--gray-color));
      transition: 0.25s;
      text-decoration: var(--link-decoration, none);
      &:hover {
        color: var(--link-hover-color, var(--primary-color));
      }
    }
  }
  .g-chips__item {
    padding-top: 6px;
    padding-bottom: 6px;
    background: rgba(236, 241, 250);
    color: var(--primary-color);
    opacity: 0.5;
    border: 2px solid transparent;
    &:hover:not(.geecko-chips__item--selected) {
      opacity: 1;
      background: rgba(236, 241, 250);
    }
    &--selected {
      opacity: 1;
      border: 2px solid #d2dcef;
    }
  }

  &__doc-container {
    margin-top: 24px;
  }

  &__doc-item {
    @apply font-croc-bold;
  }
}
.g-btn--primary {
  @apply border-none cursor-pointer;
  background: #1f44cc;
  letter-spacing: 0.02em;
  color: #ffffff;
  height: 40px;
  &:hover:not(:disabled) {
    background-color: #1d40c1;
  }
  &:hover:active {
    background-color: #1c3db6;
  }
  &:focus {
    outline: none;
  }
}
.g-btn--logout {
  @apply border-none bg-transparent flex items-center mt-32 cursor-pointer;
  color: var(--font-color, #101014);
  & svg {
    margin-right: 12px;
  }
  &:hover {
    opacity: 0.8;
  }
  &:focus {
    outline: none;
  }
}
</style>

<template>
  <div class="g-game-settings w-full dark">
    <!-- Header -->
    <icon-geecko-id-dark-theme class="g-game-settings__geecko-logo" />
    <h3 class="g-game-settings__title">Настройки игры</h3>
    <div class="g-game-settings__auth-title">Ты авторизован как</div>

    <!-- User.card Link to user geecko ID profile -->
    <a class="g-game-settings__geecko-profile" :href="profileLink" target="_blank">
      <span class="g-game-settings__geecko-profile-open"><icon-open /></span>
      <div
        class="g-game-settings__geecko-profile-photo"
        :style="{
          backgroundImage: `url('${user.photo || '/packages/images/icon-geecko-mini.svg'}')`
        }"
      ></div>
      <div class="g-game-settings__geecko-profile-name">
        {{ fullName ? fullName : user.username }}
      </div>
      <div v-if="fullName" class="g-game-settings__geecko-profile-nickname">
        {{ user.username }}
      </div>
    </a>

    <!-- Slot for anything here -->
    <slot></slot>

    <!-- Footer: logout -->
    <div class="g-game-settings__divider"></div>
    <button class="g-btn--logout" @click="logout"><icon-logout />Выйти из игры</button>

    <!-- Links to docs -->
    <div v-if="docs.length" class="g-game-settings__doc-container">
      <a
        v-for="doc in docs"
        :key="doc.name"
        class="g-game-settings__doc-item"
        :href="doc.link"
        target="_blank"
      >
        {{ doc.name }}
      </a>
    </div>
  </div>
</template>

<script>
import IconOpen from '@/components/Icons/IconOpen.vue';
import IconLogout from '@/components/Icons/IconLogout.vue';
import IconGeeckoIdDarkTheme from './IconGeeckoIdDarkTheme.vue';

// @vuese
// TODO handle subcomponents updated versions
export default {
  name: 'gActivitySettings',
  components: {
    IconOpen,
    IconLogout,
    IconGeeckoIdDarkTheme,
  },
  props: {
    user: { type: Object, required: true },
    docs: { type: Array, default: () => [] },
    logout: { type: Function, default: () => {} },
    profileLink: {
      type: String,
      default: 'https://id.geecko.com/profile',
    },
  },
  data() {
    return {};
  },
  computed: {
    fullName() {
      if (this.user.first_name && this.user.last_name) {
        return `${this.user.first_name} ${this.user.last_name}`;
      }
      return '';
    },
  },
  created() {},
  methods: {},
};
</script>
