<template>
  <div class="simple-card__scene">
    <div
      class="simple-card"
      ref="cardMain"
      :class="{ 'simple-card--flipped': isFlipped, 'simple-card--unflip': isUnflip }"
    >
      <div class="simple-card-face simple-card-backing" :style="computedStyle">
      </div>
      <div class="simple-card-face simple-card-front" ref="front" :style="computedStyle">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import ResizeObserver from 'resize-observer-polyfill';
import gsap from 'gsap';

export default {
  data() {
    return {
      isFlipped: false,
      isUnflip: false,
      height: 289,
    };
  },
  mounted() {
    const resizeOb = new ResizeObserver(((entries) => {
      const rect = entries[0].borderBoxSize;
      const { blockSize } = rect[0];
      if (blockSize && blockSize > 288) {
        this.height = blockSize;
      } else {
        this.height = 288;
      }
    }));

    if (this.$slots.default && this.$slots.default[0] && this.$slots.default[0].elm) {
      resizeOb.observe(this.$slots.default[0].elm);
    }
    if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
      this.$refs.cardMain.style.backfaceVisibility = 'hidden';
    }
  },
  computed: {
    computedStyle() {
      return {
        height: `${this.height}px`,
      };
    },
  },
  methods: {
    flip() {
      this.isFlipped = true;
    },
    unflip() {
      this.isUnflip = true;
      setTimeout(() => {
        this.isFlipped = false;
        this.isUnflip = false;
      }, 500);
    },
    appear(cb, duration = 1) {
      gsap.fromTo(this.$el, {
        opacity: 0,
      },
      {
        opacity: 1,
        onComplete: () => {
          cb(this);
        },
      });
    },
    disappear(cb, duration = 1) {
      gsap.to(this.$el, {
        duration,
        opacity: 0,
        onComplete: () => {
          cb(this);
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.simple-card {
  &__scene {
    width: 100%;
    perspective: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
  }
}

.simple-card-front {
  background-size: contain;
  border-radius: 24px;
}
.simple-card-backing {
  // background: url('/images/cardBack.svg');
  // background-color: black;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  transform: rotateY(180deg);
  border-radius: 24px;
}
.simple-card-face {
  position: absolute;
  backface-visibility: hidden;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  border-radius: 24px;
  z-index: 1;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 24px;
    padding: 1px;
    // background: linear-gradient(to right, #ff645a, #00a460);
    // -webkit-mask: linear-gradient(#fff 0 0) content-box,
    //   linear-gradient(#fff 0 0);
    // -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  &.simple-card-backing {
    z-index: 5;
  }

  &.simple-card-front {
    z-index: 4;
  }
}

.simple-card {
  height: 100%;
  width: 100%;
  min-width: 312px;
  min-height: 288px;
  position: relative;
  transform-style: preserve-3d;
  // transform: rotateY(180deg);
  background-repeat: no-repeat;
  // border-radius: 24px;
  &.simple-card--flipped {
    animation: cardFlip .35s forwards linear;

    &.simple-card-backing {
      z-index: 4;
    }

    &.simple-card-front {
      z-index: 5;
    }
  }
  &.simple-card--unflip {
    animation: cardUnFlip .35s forwards linear;
  }
}

@keyframes cardFlip {
  0% {
    opacity: 0;
    // transform: rotateZ(0deg) rotateY(180deg);
  }
  50% {
    opacity: 0.5;
    // transform: rotateZ(-10deg) rotateY(90deg);
  }
  100% {
    opacity: 1;
    transform: rotateZ(0deg) rotateY(0deg);
  }
}

@keyframes cardUnFlip {
  0% {
    // transform: rotateZ(0deg) rotateY(0deg);
    opacity: 0;
  }
  50% {
    // transform: rotateZ(-10deg) rotateY(90deg);
    opacity: 0.5;
  }
  100% {
    transform: rotateZ(0deg) rotateY(180deg);
  }
}
</style>
